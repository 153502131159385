// NotFound404Page.js
import React from 'react';
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'





const NotFound404Page = () => {
  return (
    //asdas
    <div>
      <SearchBarHeader />
      <div className="error-page">
        <div className="ui vertical masthead center aligned segment">
          <h1 className="ui header" >404</h1>
          <p>
            Oops! The page you requested is either missing or restricted.<br />
            Please log in or head back to the home page
          </p>

        </div>
      </div>
    </div>
  );
};


export default NotFound404Page







