// TermsOfService.js
import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader';
import FooterSection from '../Footer/FooterSection';
import BackToTopButton from '../BackToTopButton/BackToTopButton';

const TermsOfService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        }, []);
        
  return (
    <>
    <Helmet>
      <title>{"Terms of Service | Meeako"}</title>
    </Helmet>
    <SearchBarHeader />
    
    <div className="top-section full-height-container" style={{ 
        fontFamily: 'Arial, sans-serif', 
        lineHeight: '2', 
        color: '#333',
        textAlign: 'left', 
        marginLeft: '20%', 
        marginRight: '20%', 
      }}>  
      <h1>Terms of Service for Meeako.com</h1>
        <h2>Last Updated: May 01, 2024</h2>
        <h3>1. Acceptance of Terms</h3>
        <p>Welcome to Meeako.com. By accessing our website, you agree to be bound by these Terms of Service and to use our site in accordance with these terms, our Privacy Policy, and any additional terms and conditions that may apply. If you do not agree to these terms, please do not use our website.</p>
        <h3>2. Modification of Terms</h3>
        <p>We reserve the right, at our discretion, to change these Terms at any time. Your continued use of meeako.com after any changes indicates your acceptance of the new Terms.</p>
        <h3>3. Use of Website</h3>
        <p>Meeako.com is provided solely for your personal, non-commercial use. You may not use this site for any illegal or unauthorized purpose.</p>
        <h3>4. Intellectual Property</h3>
        <p>All content included on this site, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the site, is the property of Meeako.com or its suppliers and protected by copyright and intellectual property laws.</p>
        <h3>5. Disclaimer of Warranties</h3>
        <p>Our website is provided "as is." Meeako.com makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
        <h3>6. Limitation of Liability</h3>
        <p>Meeako.com or its suppliers will not be liable for any damages arising out of the use or inability to use the materials on Meeako.com's website, even if Meeako.com or an authorized representative has been notified orally or in writing of the possibility of such damage.</p>
        <h3>7. User Accounts</h3>
        <p>If you create an account on Meeako.com, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security.</p>
        <h3>8. Governing Law</h3>
        <p>Any claim relating to Meeako.com's website shall be governed by the laws of Florida, USA, without regard to its conflict of law provisions.</p>
        <h3>9. Contact Information</h3>
        <p>For any questions or concerns about these Terms, please contact us at <a href="mailto:info@meeako.com">info@meeako.com. </a></p> 
        <h3>Thank you for using Meeako.com!</h3>
</div> 

<div style={{ height: '50px' }}></div> {/* Add this line */}

{/* <FooterSection /> */}
<BackToTopButton />
   </>
  );
};


export default TermsOfService




