
import React from "react";
import { Link } from "react-router-dom";

const CompetitorsListComponent = ({ data }) => {
    //console.log("CompetitorsListComponent prod", data)
    //data is an array of objects
    //each object has a id, name and logo url
    //map through the array and display the name and logo
    return (
        <div>
    {data.map((competitor) => (
      <Link to={competitor.href} key={competitor.id}>
              <button
              key={competitor.id}
              className="ui secondary  circular basic button"
              //style={{ pointerEvents: "none" }}
            >
              {competitor.name}
            </button>
        </Link>
    ))}
        </div>
    );
    }   

export default CompetitorsListComponent;