// AppCategoryFilter.js
// This component is a child component of Filters.js. This component is a button which when clicked
//shows a list of app categories with a checkbox next to each category. The user can select multiple categories
//suggest list will also have search bar to search for categories.

import { useEffect, useState, useRef } from 'react'
import AppCategoriesSidebar from './AppCategoriesSidebar'
import { selectCheckedCount, uncheckAllAppCategoryFilters } from '../../../store/slices/AppCategoriesFilterSlice';
import { useSelector,useDispatch } from 'react-redux';
import { resetCategoryFilter } from '../../../store/slices/SearchFilterSlice';


const AppCategoryFilter = () => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const sliderRef = useRef(null);
  const dispatch = useDispatch();

  const isAnyFlowChecked = useSelector(state => state.appcategoriesfilter.isAnyFlowChecked);
  const filters = useSelector(state => state.appcategoriesfilter.filters);
  const appCategories = useSelector((state) => {    return state.appcategoriesfilter.filteredAppCategories}) 

  const checkedCount = useSelector(selectCheckedCount);
 //console.log(checkedCount)

  const selectedAppCatArray = Object.entries(filters).filter(([id, isChecked]) => isChecked).map(([id, _]) => appCategories.find(userflow => userflow.id === id)?.name)
  //console.log(selectedAppCatArray)
  const toggleSuggestions = () => {
    setIsSliderOpen(!isSliderOpen);
  };

  useEffect(() => {
    // Listen for clicks outside of the UserflowFilterSlider
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  
    return () => {
      // Clean up the event listeners
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    //console.log("FlowsGridiOSApp handleClickOutside event.target", event.target);
    // Select the FlowsRoundButton by its class name
    const appcatbutton = document.getElementById('appcatbutton');
    //console.log("FlowsGridiOSApp handleClickOutside FlowsRoundButton", appcatbutton);
  
    // Check if the click is on FlowsRoundButton or its descendants
    if (appcatbutton && appcatbutton.contains(event.target)) {
     // console.log("FlowsGridiOSApp handleClickOutside FlowsRoundButton asdasdasdasd");
      return; // Do nothing if the click is on FlowsRoundButton
    }
  
    // Assuming there's a class name for the slider to use for selection
    const slider = document.querySelector('.slider-container');
    // Proceed with the original logic if the click is outside the slider and not on FlowsRoundButton
    if (slider && !slider.contains(event.target)) {
      setIsSliderOpen(false);
    }
  };
  
  const handleUncheckAllFilters = (r) => {   
    const id = r.target.id;
   dispatch(uncheckAllAppCategoryFilters())
};  


  return (  
    <>
      <button id="appcatbutton" 
      //className="ui button circular" 
      className={` ${isAnyFlowChecked ? 'secondary' : ''} ui button circular`}
      onClick={toggleSuggestions}>
           {
                                isAnyFlowChecked ? 
                                <i 
                                    className={"times icon"}
                                    onClick={(e) => {
                                        e.stopPropagation(); // This stops the click event from propagating to the button
                                        handleUncheckAllFilters(e);
                                    }}
                                ></i>
                                :
                                <i 
                                    className={isSliderOpen ? "angle up icon" : "angle down icon"}
                                    onClick={(e) => {
                                        e.stopPropagation(); // This stops the click event from propagating to the button
                                        handleUncheckAllFilters(e);
                                    }}
                                ></i>
                               }

                            {/* {isAnyFlowChecked ? "App Categories ("+checkedCount+")" : 'App Categories'} */}
                            {selectedAppCatArray.length === 1 ? selectedAppCatArray[0] : selectedAppCatArray.length > 1 ? `${selectedAppCatArray[0]} + ${selectedAppCatArray.length - 1} more` : "All Categories"}
      </button>
      {isSliderOpen && (
        <div className="slider-container" ref={sliderRef}>
        <AppCategoriesSidebar isOpen={isSliderOpen} setIsOpen={setIsSliderOpen} />
        </div>
      )}
      </>
  );
};


export default AppCategoryFilter
