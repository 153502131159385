// CompareFlowsButton.js
//this is the button that user clicks and navigates to compare flows page


import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";



const CompareFlowsButton = () => {
    const navigate = useNavigate();
 
    const handleOnClick = () => {
        //console.log("Compare Flows Button Clicked")
        //navigate to compare flows page
          navigate('/compare/flows/6683097c33eadae89c03e865/66886380d45127bbd4dbb41e')
      }
      
  return (  
      <button id="" 
      className="ui button circular" 
      onClick={handleOnClick}
      >
        <i className="exchange icon"></i>
        Compare Apps
      </button>      
  );
};


export default CompareFlowsButton
