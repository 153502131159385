import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')

const fetchUserById = createAsyncThunk('fetchUserById/fetch', async ({ id }) => {
    //console.log("fetchUserById thunk ", id)
    return fetch(KEYS.GRAPHQL_URL, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            variables: {
                id
            },
            query: `
            query ($id: ID!) {
            user (id: $id) {
                success
                errorMessage
                user {
                id
                email
                name
                hasAccess
                subscription {
                    id
                    planId
                    startDate
                    endDate
                    nextBillingDate
                    planDetails {
                      type
                      name
                      priceText
                      features
                      accessDuration
                    }
                  }
                    }
                }
            }
            }
        `
        })
    }).then(res => res.json())
})

const fetchUserByEmail = createAsyncThunk('fetchUserByEmail/fetch', async ({ email }) => {
   // console.log("fetchUserByEmail thunk ", email)
    return fetch(KEYS.GRAPHQL_URL, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            variables: {
                email
            },
            query: `
            query ($email: String!) {
            user (email: $email) {
                success
                errorMessage
                user {
                id
                email
                name
                hasAccess
                subscription {
                    id
                    planId
                    startDate
                    endDate
                    nextBillingDate
                    planDetails {
                      type
                      name
                      priceText
                      features
                      accessDuration
                    }
                  }
                    }
                }
            }
    
        `
        })
    }).then(res => res.json())
})

export { fetchUserById, fetchUserByEmail }