// LandingPageWithHeroSections.js
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { websiteName } from '../../utils/environment'
import { Link } from 'react-router-dom';
import posthog from 'posthog-js';
import BackToTopButton from '../BackToTopButton/BackToTopButton';

const LandingPageWithHeroSections = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 680);

    const navigate = useNavigate();

    // useEffect(() => {

    //   posthog.capture('Page Visit', {
    //     pageName: 'LandingPageWithHeroSections',

    //   });


    // }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        navigate('/browse/ios/apps'); //adding so that the user can see the browse page to simplify the user experience
        window.addEventListener("resize", () => {
            setIsMobile(window.innerWidth <= 680);
        });
    }, []);


    const handleClick = () => {
        navigate('/browse/ios/apps');
    };



    return (
        <>
            <Helmet>
                <title>{"Browse Tech Products | " + websiteName}</title>
            </Helmet>
            {/**. add semantic ui top fizex navbar  */}
            <div className="ui borderless fixed menu" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <a className="item"
                    onClick={() => document.getElementById('1').scrollIntoView({ behavior: 'smooth' })}
                >
                    <img className="ui image extra-mini" src="/assests/icon-02.png" />
                </a>

                <div className="right menu">
                    <a
                        onClick={() => document.getElementById('1').scrollIntoView({ behavior: 'smooth' })}
                        className="item" >Marketers</a>
                    <a
                        onClick={() => document.getElementById('2').scrollIntoView({ behavior: 'smooth' })}
                        className="item" >Product Managers</a>
                    <a
                        onClick={() => document.getElementById('3').scrollIntoView({ behavior: 'smooth' })}
                        className="item" >Developers</a>
                </div>
            </div>
            {/**. add hero section with h1, h2 and p using semantic ui and inline css*/}
            <div id='1' className="hero-section" style={{ padding: "50px 0", minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center",}}>
                <div className="ui container" style={{ textAlign: "left", display: "flex", flexDirection: "column", justifyContent: "center", height: "100%", alignItems: 'flex-start' }}>
                    <h1 className="ui header" style={{ fontSize:  '4em',  }}>Explore, Analyze, Innovate</h1>
                    <h2 className="ui header" style={{ fontSize: '1.5em', marginTop: '0em', color: '#888' }}>Save Hours on Tech Products, Features & UX Research</h2>
                    {false ? "" : <p className='hero-landing'>Study the industry leaders to craft your path to leadership. Gain insights into top tech trends and strategies, and transform your approach to secure a leading position in your market.</p>}
                    <button
                        className="ui icon secondary circular button right labeled icon"
                        onClick={handleClick}
                    >
                        <i className="arrow right icon"></i>
                        Start exploring
                    </button>
                </div>
            </div>
            <div id='2' className="hero-section" style={{ backgroundColor: "#f8f8f8", padding: "50px 0", minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className="ui container" style={{ textAlign: "left", display: "flex", flexDirection: "column", justifyContent: "center", height: "100%", alignItems: 'flex-start' }}>
                    <h1 className="ui header" style={{ fontSize:  '4em' }}>Strategize, Optimize, Lead</h1>
                    <h2 className="ui header" style={{ fontSize: '1.5em', marginTop: '0em', color: '#888' }}>Enhance Strategy with Competitor Insights and Market Trends</h2>
                    {false ? "" : <p className='hero-landing'>Harness the power of competitive intelligence to fine-tune your product strategy. Dive deep into user flows and monetization techniques from successful tech products, and use these insights to drive innovation and leadership in your market</p>}
                    <button
                        className="ui icon secondary circular button right labeled icon"
                        onClick={handleClick}
                    >
                        <i className="arrow right icon"></i>
                        Start exploring
                    </button>
                </div>
            </div>
            <div id='3' className="hero-section" style={{ padding: "50px 0", minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className="ui container" style={{ textAlign: "left", display: "flex", flexDirection: "column", justifyContent: "center", height: "100%", alignItems: 'flex-start' }}>
                    <h1 className="ui header" style={{ fontSize: '4em' }}>Explore, Design, Implement</h1>
                    <h2 className="ui header" style={{ fontSize: '1.5em', marginTop: '0em', color: '#888' }}>Craft Exceptional UX with Insights from Leading Apps</h2>
                    {false ? "" : <p className='hero-landing'>Tap into a wealth of user experience designs used by top tech companies. Discover how industry leaders create engaging and delightful interfaces for their users, and apply these innovative practices to elevate the UX in your own projects.</p>}
                    <button
                        className="ui icon secondary circular button right labeled icon"
                        onClick={handleClick}
                    >
                        <i className="arrow right icon"></i>
                        Start exploring
                    </button>
                </div>
            </div>

            <BackToTopButton />

        </>
    );
};


export default LandingPageWithHeroSections

