// PrivacyPolicy.js
import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader';
import FooterSection from '../Footer/FooterSection';
import BackToTopButton from '../BackToTopButton/BackToTopButton';


const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        }, []);


return (
    <>
        <Helmet>
            <title>{"Privacy Policy | Meeako"}</title>
        </Helmet>
        <SearchBarHeader />

        <div className="top-section full-height-container" style={{ 
                fontFamily: 'Arial, sans-serif', 
                lineHeight: '2', 
                color: '#333',
                textAlign: 'left', 
                marginLeft: '20%', 
                marginRight: '20%', 
            }}>  
            <h1>Privacy Policy for Meeako.com</h1>
            <h2>Last Updated: May 01, 2024</h2>
            <br/>
            <p>At Meeako.com, protecting your privacy is a priority. This Privacy Policy outlines how we collect, use, protect, and disclose the information you provide while using our website.</p>
            <h3>Information Collection:</h3>
            <p>We collect information from you when you place an order or subscribe to our newsletter. The types of personal information collected at these pages are:</p>
            <p>1. <strong>Email Address</strong>: To communicate with you about your order, send you promotional emails, and update you about our website and services.</p>
            <p>2. <strong>Payment Information</strong>: We collect payment information through Stripe, our payment processing partner, which handles your credit/debit card information securely. No payment data is stored on our servers.</p>
            <h3>Use of Information:</h3>
            <p>The information you provide is used in the following ways:</p>
            <p>- To process your transactions quickly.</p>
            <p>- To communicate with you regarding your order.</p>
            <p>- To send periodic emails related to your order or other updates and promotional offers.</p>
            <h3>Protection of Information:</h3>
            <p>Your personal information is protected with robust security measures, including encryption and secure software. We use Stripe to handle all payment processing, ensuring your payment information is secure and in compliance with global industry standards.</p>
            <h3>Disclosure of Information:</h3>
            <p>We value your privacy and do not collect, sell, trade, or transfer your personally identifiable information to any third parties. Our commitment extends to all forms of data handling within our business operations, ensuring your personal information remains confidential and is used solely for the purposes communicated to you.</p>
            <h3>Children's Privacy:</h3>
            <p>Meeako.com does not knowingly collect, use, or disclose personal information from children under the age of 13. If we learn that we have collected personal information from a child under 13 without verification of parental consent, we will take steps to remove that information from our servers immediately. Parents or guardians who believe that Meeako.com might have any information from or about a child under 13 are encouraged to contact us so that we can take necessary actions.</p>
            <h3>Changes to Our Privacy Policy:</h3>
            <p>We may update this Privacy Policy occasionally to reflect changes to our practices or for other operational, legal, or regulatory reasons. Changes will be notified on this page.</p>
            <h3>Contacting Us:</h3>
            <p>If you have any questions about this privacy policy, please contact us at: <a href="mailto:info@meeako.com"> info@meeako.com</a></p>
            
            <h3>Thank you for using Meeako.com!</h3>
            <div style={{ height: '50px' }}></div> {/* Add this line */}
        </div> 
        <BackToTopButton />
        {/* <FooterSection />  */}
    </>
);
};


export default PrivacyPolicy


