import React from 'react';
import ReactDOM from 'react-dom';

const FullScreenModal = ({ children, onClose }) => {
  const modalRoot = document.getElementById('modal-root');
  const modalStyle = {
      position: 'fixed', // Use fixed positioning
      top: '50%', // Center vertically
      left: '50%', // Center horizontally
      transform: 'translate(-50%, -50%)', // Adjust the transform to center correctly
      width: '95%', // Almost full width
      height: '92%', // Almost full height
      backgroundColor: '#FFFFFF', // Ensure the background is white or any color
      zIndex: 1000, // Make sure it's on top of other content
      overflowY: 'hidden', // Remove vertical scroll
      padding: '20px', // Add some padding inside the modal
      borderRadius: '20px', // Round the corners
    };

  const closeButtonStyle = {
    display: 'flex', // Enable flexbox
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    position: 'absolute',
    top: '20px',
    right: '20px',
    cursor: 'pointer',
    width: '30px', // Set width
    height: '30px',
    borderRadius: '50%', // Make the background round
    backgroundColor: '#f5f5f5', // Add a background color
    padding: '5px', // Add some padding to make it look better
    fontSize: '16px', // Adjust font size as needed
    lineHeight: '1', // Ensure the line height does not affect centering
  };

  const overlayStyle = {
    position: 'fixed', // Use fixed positioning for the overlay
    top: 0, // Start from the top edge
    left: 0, // Start from the left edge
    width: '100%', // Span the full width of the viewport
    height: '100%', // Span the full height of the viewport
    //backgroundColor: 'rgba(0, 0, 0, 0.6)', // Dark transparent background
    backgroundColor: '#00000099', // Dark transparent background
    zIndex: 999, // Ensure it's below the modal but above everything else
  };
  

  return ReactDOM.createPortal(
    <>
      <div style={overlayStyle} onClick={onClose}></div> {/* Add this line for the overlay */}
      <div style={modalStyle}>
        <div style={closeButtonStyle} onClick={onClose}>X</div>
        {children}
      </div>
    </>,
    modalRoot
  );
}

export default FullScreenModal;