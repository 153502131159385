  //AppPricingsFilterSlice.js

  import { createSlice } from '@reduxjs/toolkit'
  import { fetchAllAppPricings } from '../thunks/webAppsThunk'
  import { createSelector } from 'reselect';
  import { logout } from './UserAuthSlice';
  import { resetOtherStateAfterLogin } from './UserAuthSlice';
  
  const initialState = {
    searchTerm: '',
    isLoading: false,
    appPricings: [], // This will be populated from the API
    filteredAppPricings: [], // This will hold the filtered set based on the searchTerm
    filters: {}, // Object to track the state of each filter
    isAnyFlowChecked: false // Boolean attribute
  }
  
  const AppPricingsFilterSlice = createSlice({
    name: 'apppricingsfilter',
    initialState,
    reducers: {
     changeSearchTerm: (state, action) => {
        state.searchTerm = action.payload
        if (action.payload.trim() === '') {
            state.filteredAppPricings = state.appPricings;
        } else {
          // Otherwise, filter based on the search term
          state.filteredAppPricings = state.appPricings.filter(pricing =>
            pricing.name.toLowerCase().includes(action.payload.toLowerCase())
          );
        }
      }, 
     toggleAppPricingFilter: (state, action) => {
          //console.log("toggleAppPricingFilter", action.payload)
          const appPricingID = action.payload;
          state.filters[appPricingID] = !state.filters[appPricingID];
  
          // Update isAnyFlowChecked
          state.isAnyFlowChecked = Object.values(state.filters).some(isChecked => isChecked);
      },
     uncheckAllAppPricingFilters: (state) => {
        state.appPricings.forEach(pricing => {
            state.filters[pricing.id] = false;
            });
        state.isAnyFlowChecked = false;
  
     }, 
  
    },
    extraReducers(builder){
      builder.addCase(fetchAllAppPricings.fulfilled, (state, action) => {
          //console.log(action.payload)
          //console.log(action.meta)
         state.appPricings = action.payload
         state.filteredAppPricings = action.payload
         state.filters = {}
         state.isAnyFlowChecked = false
         state.isLoading = false; // Set isLoading to false once the data is fetched
      });
      builder.addCase(fetchAllAppPricings.pending, (state) => {
                state.isLoading = true;
      });
      builder.addCase(logout, (state, action) => {
        //console.log("logout")
        return initialState
      })
      builder.addCase(resetOtherStateAfterLogin, (state, action) => {
        //console.log("resetOtherStateAfterLogin")
        return initialState
      })
      
    }
  })
  
  // Selector to count the number of checked userflows
  export const selectCheckedCount = (state) => {
      return Object.values(state.apppricingsfilter.filters).filter(isChecked => isChecked).length;
  };
  

  const getAppPricings = state => state.apppricingsfilter.appPricings;
  const getFilters = state => state.apppricingsfilter.filters;
  
  //memoized selector
    export const selectFilteredAppMonetizations = createSelector(
    [getAppPricings, getFilters],
    (appPricings, filters) => appPricings.filter(pricing => filters[pricing.id])
    );
  
  
  // Action creators are generated for each case reducer function
  export const { toggleAppPricingFilter, uncheckAllAppPricingFilters, changeSearchTerm } = AppPricingsFilterSlice.actions
  
  export const appPricingsfilterReducer = AppPricingsFilterSlice.reducer;