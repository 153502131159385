// FlowsGridWebApp.js
import React from 'react';
import SimpleLoader from '../Loaders/SimpleLoader'
//to call API to fetch flows and screens for selected App id when this component loads
 import { useEffect, useState, useRef } from 'react'
 
 import { useSelector, useDispatch } from 'react-redux'
 import { uncheckAllFilters, fetchUserFlowByAppId, fetchWebAppById, resetUserFlowsByAppId, fetchAllUserFlowNamesByAppId } from '../../store'
 import FlowsGridRowWebApp from './FlowsGridRowWebApp'
import { useParams } from 'react-router-dom';
import BackToTopButton from '../BackToTopButton/BackToTopButton'
import FlowsRoundButton from './FlowsRoundButton'
import UserflowFilterSlider from './UserflowFilterSlider'
import { selectFilteredUserFlows } from '../../store/slices/UserflowFilterSlice';

const FlowsGridWebApp = () => {
     
    const dispatch = useDispatch();
    const filteredUserFlows = useSelector(selectFilteredUserFlows);
    const isAnyFlowChecked = useSelector(state => state.userflowsfilter.isAnyFlowChecked);
    
    //const [skip, setSkip] = useState(0);
  //  const limit = 2; // Assuming a fixed limit value   
    //console.log(skip) 

    // State to track if the slider is open or not
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const sliderRef = useRef(null);

    //getting parameter
    const params = useParams();
    const idfromURL = params.id
    //console.log("FlowsGridWebApp idfromURL", idfromURL)


    const limit = useSelector((state) => {
          return state.userflows.limit
       }) 
  // console.log("limit", limit)

     var skip = useSelector((state) => {
          return state.userflows.skip
       }) 
   //console.log("skip", skip)

    const hasMore = useSelector((state) => {
         //console.log("userFlowsByAppId", state.userflows.userFlowByAppID)
          return state.userflows.hasMore
       })  
    //console.log("hasMore", hasMore)

    const appId = useSelector((state) => {
         //console.log("userFlowsByAppId", state.userflows.userFlowByAppID)
          return state.userflows.appId
       })  
    //console.log("appId", appId)


     const userFlowSByAppId = useSelector((state) => {
         //console.log("userFlowsByAppId", state.userflows.userFlowByAppID)
          return state.userflows.userFlowsByAppId
       })  
    // console.log("userFlowSByAppId", userFlowSByAppId)

     const count = useSelector((state) => {
         //console.log("count", state.userflows.userFlowByAppID)
          return state.userflows.count
       }) 

 const appIdOfPreviousUserfilters = useSelector((state) => {
          return state.userflowsfilter.appId
       })  
//console.log("appIdOfPreviousUserfilters", appId)


 useEffect(()=> {
   // console.log("useEffect")
    

        if(userFlowSByAppId.length === 0 )//|| userFlowsByAppId.appId !== idfromURL) //if the newflow (idfromURL) is same as old (userFlowsByAppId.appId) then no need to cal API use data from state
        {
            console.log("FETCHING USER FLOWS BY WEB APP ID")
           dispatch(fetchUserFlowByAppId({idfromURL, limit, skip})) 
           // dispatch(fetchUserFlowByAppId({idfromURL})) 
            //setSkip(skip + limit);
            dispatch(fetchWebAppById(idfromURL))  
           //dispatch(fetchAllUserFlowNamesByAppId(idfromURL)) 
             dispatch(uncheckAllFilters())

        }
        if(userFlowSByAppId.length !== 0 && appId !== idfromURL){
           //console.log("NOT same app ")
            dispatch(resetUserFlowsByAppId())
            dispatch(fetchUserFlowByAppId({idfromURL, limit, skip:0}))
           //dispatch(fetchAllUserFlowNamesByAppId(idfromURL)) //to fetch and reset userflowsfilter slice
             dispatch(uncheckAllFilters())
           // dispatch(fetchUserFlowByAppId({idfromURL}))
             //setSkip(skip + limit); 
        }
        if(userFlowSByAppId.length !== 0  && appIdOfPreviousUserfilters !== idfromURL){
           // console.log("appIdOfPreviousUserfilters NOT same app ID from URL")
            dispatch(uncheckAllFilters())
        }
    },
    [idfromURL]); //this helps reload the page component when url changes from /web/apps/2/flows to /web/apps/1/flows
 //note: you still need to above if condition

 useEffect(() => {
  // Listen for clicks outside of the UserflowFilterSlider
  document.addEventListener('mousedown', handleClickOutside);
  document.addEventListener('touchstart', handleClickOutside);

  return () => {
    // Clean up the event listeners
    document.removeEventListener('mousedown', handleClickOutside);
    document.removeEventListener('touchstart', handleClickOutside);
  };
}, []);

const handleClickOutside = (event) => {
  //console.log("FlowsGridiOSApp handleClickOutside event.target", event.target);
  // Select the FlowsRoundButton by its class name
  const flowsRoundButton = document.getElementById('flowsRoundButton');
  //console.log("FlowsGridiOSApp handleClickOutside FlowsRoundButton", flowsRoundButton);

  // Check if the click is on FlowsRoundButton or its descendants
  if (flowsRoundButton && flowsRoundButton.contains(event.target)) {
   // console.log("FlowsGridiOSApp handleClickOutside FlowsRoundButton asdasdasdasd");
    return; // Do nothing if the click is on FlowsRoundButton
  }

  // Assuming there's a class name for the slider to use for selection
  const slider = document.querySelector('.slider-container');
  // Proceed with the original logic if the click is outside the slider and not on FlowsRoundButton
  if (slider && !slider.contains(event.target)) {
    setIsSliderOpen(false);
  }
};



const handleNextPage = () => {
    //setSkip(skip + limit);
    skip = skip + limit
    dispatch(fetchUserFlowByAppId({idfromURL, limit, skip})) 

  
  };

  return (
    <div>
    
    { 
      
      userFlowSByAppId.length === 0? (
        <SimpleLoader/>
        ) :
        ( 
          <div className="screens-flows-container" > 
          {/* <FlowsRoundButton  isOpen={isSliderOpen} setIsOpen={setIsSliderOpen} /> */}
          {/* {isSliderOpen && <UserflowFilterSlider isOpen={isSliderOpen} setIsOpen={setIsSliderOpen} />} */}
          <FlowsRoundButton  isOpen={isSliderOpen} setIsOpen={setIsSliderOpen} />
          {isSliderOpen && (
            <div className="slider-container" ref={sliderRef}>
            <UserflowFilterSlider isOpen={isSliderOpen} setIsOpen={setIsSliderOpen} />
          </div>
          )}

          {

            !isAnyFlowChecked ? (<>

                                    <h3 className="ui header">
                                      {count > 1 ? `${count} flows` : `${count} flow`}
                                    </h3>
                                    <FlowsGridRowWebApp userFlowRows = {userFlowSByAppId}/>
                                    {hasMore && (
                                      <button className="ui secondary button" onClick={handleNextPage}>
                                        Load More
                                      </button>
                                    )}
                                </>

                            ) : (
                                    <>
                                    <h3 className="ui header">
                                      {filteredUserFlows.length > 1 ? `${filteredUserFlows.length} flows` : `${filteredUserFlows.length} flow`}
                                    </h3>
                                   <FlowsGridRowWebApp userFlowRows={filteredUserFlows} />
                                   </>
                                )
          }
          </div>
            )}
        <BackToTopButton/>
      
     </div> 
  );
};


export default FlowsGridWebApp



///this is the structure of fows row

 // <h3 className="ui header">
 //        Login
 //            <div className="sub header">4 screens</div>
 //        </h3> 
      

 //          <div className="webapp-flows-horizontal-scrollable-list-container">
 //               <ul className="list">
 //                  <li>
 //                      <img src="/images/NYT_landing_1280x800.jpg"/>
 //                    </li>
 //                   <li>
 //                     <img src="/images/NYT_landing_1280x800.jpg"/>
 //                    </li>
 //                     <li>
 //                     <img src="/images/NYT_landing_1280x800.jpg"/>
 //                    </li>
 //                     <li>
 //                      <img src="/images/NYT_landing_1280x800.jpg"/>
 //                    </li>
 //                     <li>
 //                      <img src="/images/NYT_landing_1280x800.jpg"/>
 //                    </li>
 //                    <li>
 //                      <img src="/images/NYT_landing_1280x800.jpg"/>
 //                    </li>
 //                  </ul>
 //      </div>








 // <div className="screens-flows-container"> 
   


 //      <h3 className="ui header">44 flows</h3>
 //        <h3 className="ui header">
 //        Login
 //            <div className="sub header">4 screens</div>
 //        </h3> 

       
 //          <div className="webapp-flows-horizontal-scrollable-list-container">

 //               <ul className="list">
 //                  <li>
 //                      <img src="/images/NYT_landing_1280x800.jpg"/>
 //                    </li>
 //                   <li>
 //                     <img src="/images/NYT_landing_1280x800.jpg"/>
 //                    </li>
 //                     <li>
 //                     <img src="/images/NYT_landing_1280x800.jpg"/>
 //                    </li>
 //                     <li>
 //                      <img src="/images/NYT_landing_1280x800.jpg"/>
 //                    </li>
 //                     <li>
 //                      <img src="/images/NYT_landing_1280x800.jpg"/>
 //                    </li>
 //                    <li>
 //                      <img src="/images/NYT_landing_1280x800.jpg"/>

 //                    </li>
 //                  </ul>
 //            </div> 


        
 //      </div> 