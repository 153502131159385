  // AppSearchBar.js
  import React from 'react';
  
  import { useSelector, useDispatch } from 'react-redux'
  import { useEffect, useState } from 'react'
  import AppSearchSuggestionList from './AppSearchSuggestionList'
  import {
    changeSearchTerm, 
    fetchSearchSuggestions, // search thunk
    } from '../../../store'
  

  
  
  const AppSearchBar = ({app, differentTopAppClicked, toporbottomflow, differentBottomAppClicked,}) => {
    //to keep track of local state of suggestion list
     const [isSuggestionListOpen, setSuggestionListOpen] = useState(false);
     const [searchTerm, setSearchTerm] = useState('');
     

     const [ selectedApp, setSelectedApp ] = useState(app);
    //console.log("AppSearchBar", searchTerm)
    const dispatch = useDispatch();
  
    // useEffect hook to update searchTerm when app changes
    useEffect(() => {
      if (app && app.name) {
        setSearchTerm(app.name);
      } else {
        setSearchTerm('');
      }
    }, [app]);
  
    const searchSuggestions = useSelector((state) => {
        //console.log(state)
        return state.search.searchSuggestions
     })
    //console.log(searchSuggestions)
 
  
    const handleSearchTermChange = (event) => { 
      let newTerm = event.target.value;
      
      // Remove all backslashes from newTerm
        newTerm = newTerm.replace(/\\/g, '');
  
        setSuggestionListOpen(true);  
        setSearchTerm(newTerm);
        dispatch(fetchSearchSuggestions(newTerm))
      };
  
      const handleBlur = (event) => {
      //console.log("blur")
      setSuggestionListOpen(false);
      //console.log(isSuggestionListOpen)
    };
  
    const handleSuggestionClick = (clicked)=> {
        //console.log("handleSuggestionClick", clicked)
        setSelectedApp(clicked);
        setSearchTerm(clicked.name);
        if (toporbottomflow === "top") {
          differentTopAppClicked(clicked);
        } else {
          differentBottomAppClicked(clicked);
        }

    }
  
  
    return (
    <div>     
      <div id="search" className="ui search search-bar-container">
      <div className={`ui icon input `}>
             <input className=" prompt search-bar" 
             type="text" 
             style = {{color: "black"}} 
             placeholder="Search Apps..."  
             value={searchTerm}
             //value={event.target.value}
             onChange={handleSearchTermChange}
             onBlur={handleBlur}
             />
                <i className="search icon"></i>
            
          </div>
         
           {isSuggestionListOpen  && searchSuggestions.length > 0 ? <AppSearchSuggestionList suggestions = {searchSuggestions} handleSuggestionClick={handleSuggestionClick}/>: null}
     
        </div> 
     </div>  
    );
  };
  
  
  export default AppSearchBar
  
  
  
  
  
  
  
  
  