//MySubscription.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';



const MySubscription = () => {

    const { user } = useSelector((state) => state.auth)

    const subscription = user.subscription;
   // console.log("subscription", subscription)
   const planDetails = subscription.planDetails;
    //  console.log("planDetails", planDetails)

    // // Dummy subscription data
    // const subscription = {
    //     id: '1',
    //     type: 'one_time',
    //     stripePriceId: 'stripe_price_id',
    //     name: 'Premium Plan',
    //     stripePaymentLink: 'stripe_payment_link',
    //     price: 9.99,
    //     priceText: '$9.99',
    //     createdAt: new Date().toString(),
    //     updatedAt: new Date().toString(),
    //     features: ['Feature 1', 'Feature 2', 'Feature 3'],
    //     billingPeriod: 'Monthly',
    // };



    return (
        <div className="ui segment">
            <div >
                <div className="ui text container">
                    <div style={{ marginTop: "5vh", marginBottom: "5vh" }}>
                        <h2 className="ui header" style={{ marginBottom: '5vh' }}>
                            {planDetails.name}
                            <div className="sub header">The Plan You're On</div>
                        </h2>

                        <div className="ui two column grid">
                            <div className="column">
                                <h3 className="ui header">
                                    {planDetails.priceText}
                                    <div className="sub header">{planDetails.type === 'one_time' ? 'One Time Charge' : 'Recurring Charge'}</div>
                                </h3>
                            </div>
                            <div className="column">
                                <h3 className="ui header">
                                 {planDetails.accessDuration}
                                    <div className="sub header">Access Duration</div>
                                </h3>
                            </div>
                            <div className="column">
                                <h3 className="ui header">
                                    {new Date(subscription.startDate).toLocaleDateString()}
                                    <div className="sub header">Activation Date</div>
                                </h3>
                            </div>
                            {/* {subscription.type === 'recurring' && (
                                <div className="column">
                                    <h3 className="ui header">
                                        {subscription.billingPeriod}
                                        <div className="sub header">Billing Period</div>
                                    </h3>
                                </div>
                            )} */}
                            
                            

                        </div>

                        <p style={{ marginTop: '2em', fontSize: "1em", color: '#888' }}>
                            View all plans & features on the <Link to="/pricing" style={{ textDecoration: 'underline', color: '#888' }}>Pricing</Link> page.
                        </p>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default MySubscription