// IOSAppsResultGrid.js
import React from 'react';
import { useDispatch } from 'react-redux';
import {selectediOSApp, selectedApp} from '../../store'
import { useNavigate } from 'react-router-dom';




const IOSAppsResultGrid = ({ iosapps }) => {
 // console.log(iosapps);
  //console.log("IOSAppsResultGrid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  


  //console.log("IOSAppsResultGrid",iosapps)

   
  const handleOnClick = (r) => {   
    const iosappclicked = r;
    //console.log("iosappclicked")      
     if (iosappclicked.isBlurred) {
        navigate('/signin');
    } else {
        dispatch(selectedApp(iosappclicked))
        navigate(`/ios/apps/${iosappclicked.id}/flows`);
    }
    
    };

    // Truncate function
    function truncate(str, num) {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + '...';
    }
    

  return (
   <div className="doubling stackable six column row ">
     {iosapps.map((result, i) => (

            <div className="column" key={i} style={{ marginBottom: '4vh' }}> 
                     <div onClick={()=> handleOnClick(result)} className="disabled" style={{ cursor: 'pointer' }}>
                       
                        <img className={result.isBlurred ? 'blurred ui image iosapps-images' : 'ui image iosapps-images'} src={result.initial_image_url}/>   
                        <div className="ui header">
                                    <img className="ui mini rounded image" 
                            src={result.logo_url}
                         />
                            <div className="content">
                            {truncate(result.name, 15)}            
                             </div>    
                    </div>
                          
                    </div>      
                </div>

          ))}
    </div>
  );
};


export default IOSAppsResultGrid



// <div className="ui unstackable items">
//                               <div className=" item">
//                                    <div className="ui mini image">
                                            
//                                           <img  src={result.logo_url}/>   
//                                    </div>
//                                    <div className="content">
//                                           <div className="header">{result.name}</div>
                                      
//                                    </div>
//                               </div>
//                         </div> 




