//iOSUserFlowSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { fetchiOSUserFlowByAppId, fetchIOSUserFlowById } from '../thunks/userFlowThunk'
import { logout } from './UserAuthSlice'
import { resetOtherStateAfterLogin } from './UserAuthSlice'

const initialState = {
	hasMore: false,
	appId: '',
	count: '',
	limit: 2,
	skip: 0,
	userFlowsByAppId: [],
	selectedFlow: {}, //{userFlowByID: from api}
}

const iOSUserFlowSlice = createSlice({
	name:"iosuserflow",
	initialState,
	reducers: {
		updateSelectedIOSFlow: (state, action) => {
			//console.log("updateSelectedIOSFlow action", action)
			// state.selectedFlow = {
			// 	flowId: action.payload, //has the payload i.e. flowId user clicked
			// 	 flow: state.userFlowsByAppId.find((flow) => {
    		// 	 	return flow.id === action.payload
			// 	})
			// }
			state.selectedFlow = action.payload


		},
		resetIOSUserFlowsByAppId: (state, action) => {
			//console.log("updateSelectedIOSFlow action", action)
			state.userFlowsByAppId = [];
			state.hasMore = false
			state.skip = 0
		},

	},
	extraReducers(builder){
		builder.addCase(fetchiOSUserFlowByAppId.fulfilled, (state, action) => {
     	//console.log("slice",action.payload.data.userFlowByAppId.userflow)
		state.skip = action.meta.arg.skip,
		state.hasMore = action.payload.data.userFlowByAppId.hasMore,
		state.appId = action.meta.arg.idfromURL,
		state.count = action.payload.data.userFlowByAppId.count,
		state.userFlowsByAppId = state.userFlowsByAppId.concat(action.payload.data.userFlowByAppId.userflow)
    });
	builder.addCase(fetchIOSUserFlowById.fulfilled, (state, action) => {
     //console.log("fetchIOSUserFlowById", action.payload.data.userFlowId)
      // state.selectedFlow = {
	// 			flowId: action.meta.arg, 
	// 			 flow: action.payload.data.userFlowId
	// 		}

     state.selectedFlow = action.payload.data.userFlowId

    });	
	builder.addCase(logout, (state, action) => {
		//console.log("logout")
		return initialState
	})
	builder.addCase(resetOtherStateAfterLogin, (state, action) => {
		//console.log("resetOtherStateAfterLogin")
		return initialState
	})
	}
})


export const { updateSelectedIOSFlow, resetIOSUserFlowsByAppId } = iOSUserFlowSlice.actions;
export const iOSFlowReducer = iOSUserFlowSlice.reducer;