// IOSLandingPage.js
import React from 'react';
import MobileWebTile from '../MobileWebTile'
import IOSAppsResultGrid from './IOSAppsResultGrid'
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'

//to call API when this component loads
 import { useEffect } from 'react'
 import { fetchInitialiOSApps } from '../../store'
 import { useSelector, useDispatch } from 'react-redux'
 import AppFilters from '../AppWideFiltersSection/AppFilters';



const IOSLandingPage = () => {
  const dispatch = useDispatch();
  const { categories, monetizations, priceRanges } = useSelector(state => state.searchfilter);
 

 // Define iosapps at the top level of the component
 const iosapps = useSelector((state) => {
  if (categories.length > 0 || monetizations.length > 0 || priceRanges.length > 0) {
    return state.iosapps.filteredApps;
  } else {
    return state.iosapps.initialiOSApps;
  }
});


 //calls when compenent is loaded
    useEffect(()=> {
      //To make sure a page is scrolled to the top on component load 
    window.scrollTo(0, 0);
        //fetch apps only if webapps array is 0 i.e on inital load
        if(iosapps.length === 0){
        console.log("FETCHING INITIAL IOS APPS")
        dispatch(fetchInitialiOSApps())
        }
        
    },[]);

  return (
  //asdasdasd
  <div>     
    <SearchBarHeader/>
       <div className="ui top-section">
            <div className="ui text ">
              <h1 className="ui header">
                Browse iOS Apps
              </h1> 
            </div>
        </div>  

        <AppFilters/>

        <MobileWebTile active="ios"/>

         <div className="ui right-left-spacing">   

                <div className=" ui grid">
                 <IOSAppsResultGrid iosapps={iosapps}/>
                </div>
           
        </div>

   </div>  
  );
};


export default IOSLandingPage








