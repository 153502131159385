  // UserflowSearchBar.js
  import React from 'react';
  
  import { useSelector, useDispatch } from 'react-redux'
  import { useEffect, useState } from 'react'
  import UserflowSuggestionList from './UserflowSuggestionList'
 import  { searchUserflowByName } from '../../../store/thunks/userFlowThunk'
  

  
  
  const UserflowSearchBar = ({app, userflow, updateTopFlowId, toporbottomflow, updateBottomFlowId}) => {
    //to keep track of local state of suggestion list
     const [isSuggestionListOpen, setSuggestionListOpen] = useState(false);
     const [searchTerm, setSearchTerm] = useState('');
     const [ searchSuggestions , setSearchSuggestions ] = useState([]);

     const [ selectedApp, setSelectedApp ] = useState(app);
    //console.log("UserflowSearchBar", searchTerm)
    const dispatch = useDispatch();
  
    // useEffect hook to update searchTerm when app changes
    useEffect(() => {
      if (userflow && userflow.name) {
        setSearchTerm(userflow.name);
      } else {
        setSearchTerm('');
      }
    }, [userflow, app]);
  
   
    //console.log(searchSuggestions)
 
  
    const handleSearchTermChange = (event) => { 
      let newTerm = event.target.value;
      
      // Remove all backslashes from newTerm
        newTerm = newTerm.replace(/\\/g, '');
  
        setSuggestionListOpen(true);  
        setSearchTerm(newTerm);
        dispatch(searchUserflowByName({appId: app.id, flowName: newTerm})).then((res) => {
          //console.log("searchUserflowByName", res.payload.data.searchUserFlowsByName)
          setSearchSuggestions(res.payload.data.searchUserFlowsByName)
        })
      };
  
      const handleBlur = (event) => {
      //console.log("blur")
      setSuggestionListOpen(false);
      //console.log(isSuggestionListOpen)
    };
  
    const handleSuggestionClick = (clicked)=> {
        //console.log("handleSuggestionClick", clicked)
        setSelectedApp(clicked);
        setSearchTerm(clicked.name);
        if (toporbottomflow === "top") {
          updateTopFlowId(clicked);
        } else {
          updateBottomFlowId(clicked);
        }
        
    }
  
  
    return (
    <div>     
      <div id="search" className="ui search search-bar-container">
      <div className={`ui icon input `}>
             <input className=" prompt search-bar" 
             type="text" 
             style = {{color: "black"}} 
             placeholder={`Search ${app.name} flows...`}  
             value={searchTerm}
             //value={event.target.value}
             onChange={handleSearchTermChange}
             onBlur={handleBlur}
             />
                <i className="search icon"></i>
            
          </div>
         
           {isSuggestionListOpen  && searchSuggestions.length > 0 ? <UserflowSuggestionList suggestions = {searchSuggestions} handleSuggestionClick={handleSuggestionClick}/>: null}
     
        </div> 
     </div>  
    );
  };
  
  
  export default UserflowSearchBar
  
  
  
  
  
  
  
  
  