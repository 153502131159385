// AppPricingsSidebar.js
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {  toggleAppPricingFilter } from '../../../store'
import { fetchAllAppPricings} from '../../../store/thunks/webAppsThunk'
import { useParams } from 'react-router-dom';
import SimpleLoader from '../../Loaders/SimpleLoader'
import { changeSearchTerm } from '../../../store/slices/AppMonetizationsFilterSlice';
import { addPriceRange, removePriceRange } from '../../../store';


const AppPricingsSidebar = ({ isOpen, setIsOpen }) => {
const dispatch = useDispatch();


const appPricings = useSelector((state) => {
          return state.apppricingsfilter.filteredAppPricings
       }) 
//console.log("appPricings", appPricings)

const searchTerm = useSelector((state) => {
      return state.apppricingsfilter.searchTerm
   })



const filters = useSelector(state => state.apppricingsfilter.filters);
//console.log("filters", filters)


const isAnyFlowChecked = useSelector(state => state.apppricingsfilter.isAnyFlowChecked);
//console.log("isAnyFlowChecked", isAnyFlowChecked)



 const isLoading = useSelector((state) => {
          return state.apppricingsfilter.isLoading
       })  
//console.log("isLoading", isLoading)



useEffect(() => {

   if(appPricings.length === 0){
   dispatch(fetchAllAppPricings())
   }
    
  }, []);



const handleCloseSlider = (r) => {   
        const id = r.target.id;
         setIsOpen(false)
    };  

  const handleCheckboxChange = (price) => (event) => {
    const { id } = price;
    const { checked } = event.target;
    //console.log("handleCheckboxChange", price);
    //console.log("handleCheckboxChange", checked);
    dispatch(toggleAppPricingFilter(price.id));
    if (checked) {
      dispatch(addPriceRange(price));
    } else {
      dispatch(removePriceRange(price));
    }
  }; 

const handleSearchTermChange = (event) => { 
      const newTerm = event.target.value;
      dispatch(changeSearchTerm(newTerm))
    };      
      

return (
    <div className="ui vertical right visible sidebar menu">
                      <h3 className="item">
                      Prices
                      </h3>
                      

                     {/* <div id="search" className="ui search ">
                     <div className="ui icon input">
                       <input className=" prompt search-bar-for-flows-dropdown" 
                       type="text" 
                       style = {{color: "black"}} 
                       placeholder={"Search app monetizations.."} 
                       value={searchTerm}
                       onChange={handleSearchTermChange}
                       //onBlur={handleBlur}
                       />
                          <i className="search icon"></i>
                      
                    </div>
                    </div> 
                    <div className="ui divider"></div>
                    */}

                     {
                       isLoading ? 
                       <SimpleLoader/> : 
                       <>
                       { isAnyFlowChecked && 
                        <>
                        
                              {Object.entries(filters).filter(([id, isChecked]) => isChecked).map(([id, _]) => {
                                const matchedPricing = appPricings.find(price => price.id === id);
                                return matchedPricing ? (
                                  <div className="item" key={id}>
                                    <div className="ui checkbox">
                                      <input 
                                        type="checkbox" 
                                        checked={true}
                                        //onChange={handleCheckboxChange(id)}
                                        onChange={handleCheckboxChange(matchedPricing)}
                                      />
                                      <label>{matchedPricing.name}</label>
                                    </div>
                                  </div>
                                ) : null;
                              })}
                              <br/>
                         </>
                         }

                    <div className='item'>
                        <h4 className="ui header">Monthly Fixed Prices
                        <div className="sub header">e.g. Subscription</div>
                        </h4>
                     </div> 
                        {appPricings
                          .filter(price => price.type === "fixed")
                          .map(price => (
                            <div className="item" key={price.id}>
                              <div className="ui checkbox">
                                <input 
                                  type="checkbox" 
                                  name="example"
                                  checked={filters[price.id] || false}
                                  //onChange={handleCheckboxChange(price.id)}
                                  onChange={ handleCheckboxChange(price)}
                                />
                                <label>{price.name}</label>
                              </div>
                            </div>
                          ))}
                     <br/>
                     <div className='item'>
                        <h4 className="ui header">Monthly Percentage Prices
                        <div className="sub header">e.g. Transaction Fees</div>
                        </h4>
                     </div>  
                     {appPricings
                          .filter(price => price.type === "percentage")
                          .map(price => (
                            <div className="item" key={price.id}>
                              <div className="ui checkbox">
                                <input 
                                  type="checkbox" 
                                  name="example"
                                  checked={filters[price.id] || false}
                                  //onChange={handleCheckboxChange(price.id)}
                                  onChange={handleCheckboxChange(price)}
                                />
                                <label>{price.name}</label>
                              </div>
                            </div>
                          ))}
                       </>
                     }
                  
  </div> 
  );
};


export default AppPricingsSidebar






