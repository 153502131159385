// CompareFlowsPage.js
//this is the page where user can compare flows. This page will have two rows of flows.
//top section will have user flow of one app and bottom section will have user flow of another app.
//user can scroll horizontally to see all the screens of the flow.
//users will be able horizontally scroll individual screens of the flow 
//or user will be able scroll both flows will be in sync.
//this will have an header to select the app and user flow to compare.

import { useEffect, useState } from 'react'
import FullScreenModal from './FullScreenModal'
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from "react-helmet";
import { websiteName } from '../../utils/environment';
import { fetchTopAndBottomFlowById } from '../../store/thunks/userFlowThunk'
import SimpleLoader from '../Loaders/SimpleLoader';
import SearchTopAndBottomFlowsModal from './SearchTopAndBottomFlowsModal';
import AppSearchBar from './AppSearchPages/AppSearchBar';





const CompareFlowsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //getting parameter from page url (flow id)
    const params = useParams();

    // State to manage modal visibility
    const [isModalVisible, setIsModalVisible] = useState(false);
    // Function to toggle modal visibility
    const toggleModal = () => setIsModalVisible(!isModalVisible);
    const [clickedFlow, setClickedFlow] = useState(null);
    const [topFlowId, setTopFlowId] = useState(params.topflowid);
    const [bottomFlowId, setBottomFlowId] = useState(params.bottomflowid);
    const [isLoading, setIsLoading] = useState(true);
    const [topFlow, setTopFlow] = useState(null);
    const [bottomFlow, setBottomFlow] = useState(null);

    const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);

    // State to control overlay visibility
    const [showOverlay, setShowOverlay] = useState(true);
    const [showBottomOverlay, setShowBottomOverlay] = useState(true)

    // Function to toggle overlay visibility
    const toggleOverlay = () => setShowOverlay(!showOverlay);
    const toggleBottomOverlay = () => setShowBottomOverlay(!showBottomOverlay);

  

    // console.log("topFlowId", topFlowId)
    // console.log("bottomFlowId", bottomFlowId)

    const handleContextMenu = (event) => {
        event.preventDefault(); // Prevent the default context menu from opening
    };

    // const handleFlowClick = (flow) => {
    //     setClickedFlow(flow)
    //     toggleModal()
    // }

    const handleTopFlowClick = (flow) => {
        setClickedFlow(flow)
        toggleModal()
        setShowOverlay(false)
    }

    const handleBottomFlowClick = (flow) => {
        setClickedFlow(flow)
        toggleModal()
        setShowBottomOverlay(false)
    }

    const handleSearchModalClick = () => {
        //toggleModal()
        setIsSearchModalVisible(true)

    }

    const updateTopFlowId = (flow) => {
        setTopFlowId(flow.id) 
        const newPath = `/compare/flows/${flow.id}/${bottomFlowId}`;
        navigate(newPath); // Navigate to the new path
    }

    const updateBottomFlowId = (flow) => {
        setBottomFlowId(flow.id)
        const newPath = `/compare/flows/${topFlowId}/${flow.id}`;
        navigate(newPath); 
    }

    //this function swaps topFlow and bottomFlow
    const handleSwapFlows = () => {
        const temp = topFlow;
        setTopFlow(bottomFlow);
        setBottomFlow(temp);      
    }

    const getFlowName = (flow) => {
        let flowName;
        if (flow.name.includes("/")) {
            const parts = flow.name.split("/");
            flowName = parts[1];
        } else {
            flowName = flow.name;
        }
        return flowName;
    };


    useEffect(() => {
        //setIsLoading(true)
        dispatch(fetchTopAndBottomFlowById({ topflowid: topFlowId, bottomflowid: bottomFlowId })).then((res) => {
            //console.log("fetchTopAndBottomFlowById", res.payload.data)
            const { top, bottom } = res.payload.data
            //console.log("top", top)
            setTopFlow(top)
            setBottomFlow(bottom)

            setIsLoading(false)
        })

    }, [topFlowId, bottomFlowId])

    if (isLoading || !topFlow || !bottomFlow) {
        return <SimpleLoader />
    }

    return (
        <>
            <Helmet>
                <title>Compare flows | {websiteName}</title>
            </Helmet>
            <div className="ui right-left-spacing">
                <div className="iosapp-flows-horizontal-scrollable-list-container" >
                    {showOverlay && (
                        <div className="overlay" onClick={toggleOverlay}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={topFlow.app.logo_url} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
                                    <p style={{ fontSize: '1.5em' }}>{topFlow.app.name} / {getFlowName(topFlow)}</p>
                                </div>
                                <p style={{ fontSize: '1em' }}>click to continue</p>
                            </div>
                        </div>
                    )}

                    <ul className="compare-flow-list">
                        {
                            topFlow.screens.screen.map((screen, i) => (
                                //selectedFlow.flow.screens.screen.map((screen, i) => (

                                <li key={i}
                                    onClick={() => handleTopFlowClick(topFlow)}
                                    style={{ cursor: 'pointer' }}
                                    onContextMenu={handleContextMenu}>
                                    <img className="compare-flows-page-images" src={screen.image.image_url} />
                                    <p style={{ fontSize: '1em' }}>{screen.description}</p>
                                </li>

                            ))
                        }
                    </ul>
                </div>
                <br />
                <div className="iosapp-flows-horizontal-scrollable-list-container" >
                    {/* this overlay shows the name of the app and the flow */}
                    {showBottomOverlay && (
                        <div className="overlay" onClick={toggleBottomOverlay}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={bottomFlow.app.logo_url} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
                                    <p style={{ fontSize: '1.5em' }}>{bottomFlow.app.name} / {getFlowName(bottomFlow)}</p>
                                </div>
                                <p style={{ fontSize: '1em' }}>click to continue</p>
                            </div>
                        </div>
                    )}
                    <ul className="compare-flow-list">
                        {bottomFlow.screens.screen.map((screen, i) => (
                            <li key={i}
                                onClick={() => handleBottomFlowClick(bottomFlow)}
                                onContextMenu={handleContextMenu}
                                style={{ cursor: 'pointer' }}>
                                <img className="compare-flows-page-images" src={screen.image.image_url} />
                                <p style={{ fontSize: '1em' }}>{screen.description}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                {isModalVisible && (
                    
                    <FullScreenModal onClose={toggleModal} flow={clickedFlow}>
                        {/* Content you want to display in the modal */}
                        {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5vh' }}>
                            <img src={clickedFlow.app.logo_url} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
                            <p><span style={{ fontSize: '1.25em' }}>{getFlowName(clickedFlow)}</span> | <span style={{ color: '#888', fontSize: '1em' }}>{clickedFlow.screens.count} screens | {clickedFlow.userInteractionsCount} User Interactions </span></p>
                        </div> */}
                        <h3 className="ui header" style={{marginBottom: '1vh'}}>
                            <img src={clickedFlow.app.logo_url}/>
                            <div className="content">
                                {clickedFlow.app.name} / {getFlowName(clickedFlow)}
                                <div className="sub header">
                                  {clickedFlow.screens.count} screens
                                  {clickedFlow.userInteractionsCount > 0 && ` | ${clickedFlow.userInteractionsCount} User Interactions`}
                                </div>
                            </div>
                            </h3>
                            <p style={{ fontSize: '1em', color: '#888'}}>{clickedFlow.description}</p>
                        <div className="iosapp-flows-horizontal-scrollable-list-container">
                            <ul className="compare-flow-list">
                                {
                                    clickedFlow.screens.screen.map((screen, i) => (
                                        <li key={i} onContextMenu={handleContextMenu}>
                                            <img className="flow-details-page-webapp-images" src={screen.image.image_url} />
                                            <p style={{ fontSize: '1em' }}>{screen.description}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </FullScreenModal>
                )}
                {!isModalVisible && (
                    <>
                        <button style={{
                            position: 'fixed',
                            right: '20px',
                            bottom: '20px',
                            zIndex: 1001,
                            cursor: 'pointer',
                            borderRadius: '50%',
                            background: 'linear-gradient(45deg, #007bff, #8811c5)',
                            color: 'white',
                            border: 'none',
                            width: '40px',
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '24px',
                        }} onClick={handleSearchModalClick}>
                              <i className="fas fa-sliders-h" style={{ fontSize: '16px' }}></i>
                            </button>

                        <button style={{
                            position: 'fixed',
                            left: '20px',
                            top: '20px',
                            zIndex: 1001,
                            cursor: 'pointer',
                            borderRadius: '50%',
                            //background: 'linear-gradient(45deg, #000000, #434343)',
                            background: '#888',
                            color: 'white',
                            border: 'none',
                            width: '40px',
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '15px',
                        }} onClick={() => navigate("/browse/ios/apps")}>X</button>

                        {
                            isSearchModalVisible && 
                            <SearchTopAndBottomFlowsModal 
                            onClose={() => setIsSearchModalVisible(false)} 
                            top={topFlow}
                            bottom={bottomFlow}
                            handleSwapFlows={handleSwapFlows}
                            updateTopFlowId={updateTopFlowId}
                            updateBottomFlowId={updateBottomFlowId}
                            /> 

                        }
                    </>
                )}
            </div>
        </>
    );
};


export default CompareFlowsPage
