// FlowsGridImageRowWebApp;.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {updateSelectedFlow} from '../../store'




const FlowsGridImageRowWebApp = ({screens, flowId, flow}) => {
    const dispatch = useDispatch();

  //console.log(screens)
  //console.log(flowId)

const handleClickButton = () => {
    //trigger flow selection 
   //navigate(-1)
   //dispatch(updateSelectedFlow(flowId))
   dispatch(updateSelectedFlow(flow))
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu from opening
  };


  return (
  //asdas
   <div className="webapp-flows-horizontal-scrollable-list-container">
    <Link to={"/web/apps/flows/"+flowId}
    onClick={()=> handleClickButton(flowId)}
    >
    
   <ul className="web-list">
  
   {
      screens.map((screen, i) => (

        <li key={i} onContextMenu={handleContextMenu}>
                <img src={screen.image.image_url}/>
            </li>
            
          ))
    }
   </ul> 
   </Link>
  </div> 
  );
};


export default FlowsGridImageRowWebApp;







