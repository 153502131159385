//EmailUsToRegister.js
//This component ask users to email us to register
//Also informs that we are in beta

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
//import { userLogInWithEmailAndPassword, resetOtherStateAfterLogin } from '../../store'
//import SimpleLoader from '../Loaders/SimpleLoader'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'
import { websiteName } from '../../utils/environment'

const EmailUsToRegister = ({ setCurrentStep,setEmail, email}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasError, setHasError] = useState(false);
    //const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const handleOnContinueClick = () => {
        // Check if the email is valid
        // If valid, move to the next step
        // Else, set hasError to true
        setCurrentStep(2);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const focusStyle = {
        borderRadius: '5px',
        borderWidth: '1px',
        height: '30px',
        borderColor: isFocused ? 'lighgrey' : '#888', // Change borderColor on focus to black
        boxShadow: isFocused ? '0 0 16px rgba(0, 0, 0, 0.1)' : 'none', // Optional: Add shadow on focus
        outline: 'none',
        marginBottom: hasError ? '0vh' : '4vh'
    };

    return (
        <>
        <SearchBarHeader/>

            <div className="first-container" style={{
                height: '100vh', // Make sure the parent container is full viewport height
                display: 'flex',
                justifyContent: 'center', // Center children horizontally in the container
                alignItems: 'center' // Center children vertically in the container
            }}>
                <div style={{
                    border: '1px solid #ccc',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Adjusted to add shadow on all sides
                    //padding: '20px',
                    marginTop: '10vh', // Move up the form by adjusting the top margin using viewport height
                    marginBottom: '40px', // Increase bottom margin to maintain overall spacing
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '90%', // Use a larger percentage for smaller screens
                    maxWidth: '450px', // Ensure it doesn't get too wide on larger screens
                    borderRadius: '10px' // Added to make corners round
                }}>
                    <h4 style={{ marginBottom: '2px', paddingTop: '30px' }}>We are in beta</h4>
                    <p style={{ fontSize: "1em", color: '#888', marginTop: '2px', marginBottom: '4vh', paddingLeft: '4vw', paddingRight: '4vw', textAlign: 'center' }}>Testing with a select group. Please email us at <a href="mailto:info@meeako.com?subject=New%20Access%20Request">info@meeako.com</a> to request access.</p>
                    
                    <div className="second-contained" style={{ marginTop: '10px', width: '100%', backgroundColor: '#f9f9f9', border: '1px solid #dcdcdc', padding: '20px', borderRadius: '0 0 5px 5px', textAlign: 'center' }}>
                        <p style={{ fontSize: "0.9em", color: '#888', marginTop: '1px' }}>
                            Already have an account? <Link to="/signin" style={{ color: '#333', cursor: 'pointer', textDecoration: 'none' }}>Sign in</Link>
                        </p>
                    </div>
                </div>

            </div>


        </>
    );
}

export default EmailUsToRegister;