// ProfilePage.js
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'
import TopMenuForProfile from './TopMenuForProfile'
import PasswordReset from './PasswordReset'
import MySubscription from './MySubscription';



const ProfilePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }
  , []);
  
  return (
  <div >     
    <SearchBarHeader/>
        <div className="ui top-section">
            <div className="ui text ">
              <h1 className="ui header">
                Profile
              </h1> 
        </div>  
        <TopMenuForProfile/>
        <Routes>
        <Route path="password" element={<PasswordReset />} />
        <Route path="plan-and-billing" element={<MySubscription />} />
      
        {/* Define more routes as necessary */}
      </Routes>
         </div>   

</div>  
  );
};


export default ProfilePage





