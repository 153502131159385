import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import AppSearchBar from '../CompareFlowsPages/AppSearchPages/AppSearchBar';
import UserflowSearchBar from '../CompareFlowsPages/UserFlowSearchPages/UserflowSearchBar';


const SearchTopAndBottomFlowsModal = ({ children, onClose, top, bottom, handleSwapFlows, updateTopFlowId, updateBottomFlowId }) => {

  const transformApp = (topData) => {
    // Assuming topData has an 'app' property and you want to modify it
    const modifiedApp = { ...topData.app };
    return modifiedApp;
  };

  const transformUserflow = (topData) => {
    // Assuming topData has an 'app' property and you want to modify it
    const { app, ...topUserFlow } = topData;
    return topUserFlow;
  };



  const [isMobile, setIsMobile] = useState(window.innerWidth <= 680);
  const [topApp, setTopApp] = useState(transformApp(top));
  const [topUserFlow, setTopUserFlow] = useState(transformUserflow(top));
  const [bottomApp, setBottomApp] = useState(transformApp(bottom));
  const [bottomUserFlow, setBottomUserFlow] = useState(transformUserflow(bottom));

  //console.log("Top App: ", topApp);
  //console.log("Top User Flow: ", topUserFlow);
  //console.log("Bottom Apps: ", bottomApp);
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth <= 680);
    });
  }, []);


  

  const modalRoot = document.getElementById('modal-search-top-and-bottom-flows-root');
  const modalStyle = {
    position: 'fixed', // Use fixed positioning
    top: '50%', // Center vertically
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Adjust the transform to center correctly
    width: isMobile ? '90vw' : '55vw', // Use 90vw on mobile, 55vw otherwise
    height: isMobile ? '60vh' : '62vh', // Use 80vh on mobile, 62vh otherwise
    backgroundColor: '#FFFFFF', // Ensure the background is white or any color
    zIndex: 1000, // Make sure it's on top of other content
    overflowY: 'hidden', // Remove vertical scroll
    padding: '20px', // Add some padding inside the modal
    borderRadius: '20px', // Round the corners
  };

  const closeButtonStyle = {
    display: 'flex', // Enable flexbox
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    position: 'absolute',
    top: '20px',
    right: '20px',
    cursor: 'pointer',
    width: '30px', // Set width
    height: '30px',
    borderRadius: '50%', // Make the background round
    backgroundColor: '#f5f5f5', // Add a background color
    padding: '5px', // Add some padding to make it look better
    fontSize: '16px', // Adjust font size as needed
    lineHeight: '1', // Ensure the line height does not affect centering
  };

  const overlayStyle = {
    position: 'fixed', // Use fixed positioning for the overlay
    top: 0, // Start from the top edge
    left: 0, // Start from the left edge
    width: '100%', // Span the full width of the viewport
    height: '100%', // Span the full height of the viewport
    //backgroundColor: 'rgba(0, 0, 0, 0.6)', // Dark transparent background
    backgroundColor: '#00000099', // Dark transparent background
    zIndex: 999, // Ensure it's below the modal but above everything else
  };

 const handleSwapButtonClicked = (e) => {
    e.preventDefault();
    const temp = topApp;
    setTopApp(bottomApp);
    setBottomApp(temp);

    const tempFlow = topUserFlow;
    setTopUserFlow(bottomUserFlow);
    setBottomUserFlow(tempFlow);
    handleSwapFlows();
 }

 const differentTopAppClicked = (app) => {
  //console.log("differentTopAppClicked", app)
  setTopApp(app);
  setTopUserFlow({});
 }

 const differentBottomAppClicked = (app) => {
 // console.log("differentBottomAppClicked", app)
  setBottomApp(app);
  setBottomUserFlow({});
  }

  return ReactDOM.createPortal(
    <>
      <div style={overlayStyle} onClick={onClose}></div> {/* Add this line for the overlay */}
      {/* <div style={modalStyle}> */}
      <div className='compare-modal width-height'>
        <div style={closeButtonStyle} onClick={onClose}>X</div>
        <div className="ui text container">
          <div style={{ marginBottom: "10vh" }}>
            <h2 className="ui header">
              Compare Flows
              <div className="sub header">Select apps and their corresponding flows to compare them.</div>

            </h2>
            <div style={{ marginTop: '10vh', paddingLeft:'2vw' }}>
              <form className="ui form">
                <div className="fields">

                  <div className="field">
                  <label>Top App</label>
                    <AppSearchBar app={topApp} toporbottomflow="top" differentTopAppClicked={differentTopAppClicked} differentBottomAppClicked={differentBottomAppClicked}/>
                  </div>
                  
                  <div className="field">
                  <label>Top user flow</label>
                    <UserflowSearchBar app={topApp} toporbottomflow="top" userflow={topUserFlow} updateTopFlowId={updateTopFlowId} updateBottomFlowId={updateBottomFlowId}/>
                  </div>
                </div>
                
                <div className=" fields">
                 

                  <div className="field">
                    <label>Bottom App</label>
                  <AppSearchBar app={bottomApp} toporbottomflow="bottom" differentTopAppClicked={differentTopAppClicked} differentBottomAppClicked={differentBottomAppClicked}/>
                  </div>
                  <div className="field">
                    <label>Bottom user flow</label>
                    <UserflowSearchBar app={bottomApp} toporbottomflow="bottom" userflow ={bottomUserFlow} updateTopFlowId={updateTopFlowId} updateBottomFlowId={updateBottomFlowId}/>
                  </div>
                </div>
                {/* <div style={{ marginTop: '5vh' }} > */}
                {/* <button disabled={false} className="ui secondary circular button" onClick={handleSwapButtonClicked}>
                  <i className="sync icon"></i>
                  Swap
                </button> */}
                  {/* <button disabled={true} className="ui secondary circular button" type="submit">
                    <i className="exchange icon"></i>
                    Compare
                  </button> */}
                  {/* {true ? <button className='ui circular button' > <i className="times circle icon"></i> Clear</button> : null} */}
                {/* </div> */}
              </form>
            </div>
          </div>
        </div>

      </div>
    </>,
    modalRoot
  );
}

export default SearchTopAndBottomFlowsModal;