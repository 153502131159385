import { createSlice } from '@reduxjs/toolkit';
import { uncheckAllAppCategoryFilters } from './AppCategoriesFilterSlice';
import { uncheckAllAppMonetizationFilters } from './AppMonetizationsFilterSlice';
import { uncheckAllAppPricingFilters } from './AppPricingsFilterSlice';
import { logout , resetOtherStateAfterLogin} from './UserAuthSlice';

const initialState = {
  categories: [],
  monetizations: [],
  priceRanges: [],
};

const searchFiltersSlice = createSlice({
  name: 'searchFilters',
  initialState,
  reducers: {
    addCategory(state, action) {
      state.categories.push(action.payload);
    },
    removeCategory(state, action) {
      state.categories = state.categories.filter(id => id !== action.payload);
    },
    addMonetization(state, action) {
      state.monetizations.push(action.payload);
    },
    removeMonetization(state, action) {
      state.monetizations = state.monetizations.filter(id => id !== action.payload);
    },
    addPriceRange(state, action) {
      //NOTE: payload is an object with id and name and type
      //e.g.  {id: '3', name: '$50 - $99.99', type: 'fixed'}
      state.priceRanges.push(action.payload);
    },
    removePriceRange(state, action) {
      //NOTE: payload is an object with id and name and type
      //e.g.  {id: '3', name: '$50 - $99.99', type: 'fixed'}
      state.priceRanges = state.priceRanges.filter(price => price.id !== action.payload.id);
    },
   
  },
  extraReducers(builder){
    builder.addCase(uncheckAllAppCategoryFilters, (state) => {
      state.categories = [];
    });
    builder.addCase(uncheckAllAppMonetizationFilters, (state) => {
      state.monetizations = [];
    });
    builder.addCase(uncheckAllAppPricingFilters, (state) => {
      state.priceRanges = [];
    });
    builder.addCase(logout, (state) => {
      return initialState;
    });
    builder.addCase(resetOtherStateAfterLogin, (state) => {
      return initialState;
    });
  
  }
});

export const {
  addCategory, removeCategory,
  addMonetization, removeMonetization,
  addPriceRange, removePriceRange
} = searchFiltersSlice.actions;

export const searchfilterReducer = searchFiltersSlice.reducer;