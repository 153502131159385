//New Login Page with inline css styling

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { userLogInWithEmailAndPassword, resetOtherStateAfterLogin } from '../../store'
import SimpleLoader from '../Loaders/SimpleLoader'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'
import { websiteName } from '../../utils/environment'
import EnterEmail from './multistepLoginComponents/enterEmailComponent';
import EnterPassword from './multistepLoginComponents/enterPassComponent';

const MultistepLogInWithEmailAndPassword = () => {
        
    const [currentStep, setCurrentStep] = useState(1);
    const [email, setEmail] = useState("");

  

    return (
        <>
            <Helmet>
                <title>{"Sign In  | " + websiteName}</title>
            </Helmet>
            <SearchBarHeader />

             {/* <EnterEmail />
            <EnterPassword /> */}
                  {currentStep === 1 && <EnterEmail setCurrentStep={setCurrentStep} setEmail={setEmail} email={email} />}
                  {currentStep === 2 && <EnterPassword setCurrentStep={setCurrentStep} email={email} />}
                       


        </>
    );
}

export default MultistepLogInWithEmailAndPassword;