// FlowsGridiOSApp.js
import React from 'react';
import SimpleLoader from '../Loaders/SimpleLoader'
//to call API to fetch flows and screens for selected App id when this component loads
 import { useEffect, useState, useRef } from 'react'
 
 import { useSelector, useDispatch } from 'react-redux'
 import { uncheckAllFilters, fetchAllUserFlowNamesByAppId, fetchiOSUserFlowByAppId, fetchiOSAppById, resetIOSUserFlowsByAppId } from '../../store'
 //import FlowsGridRowWebApp from './FlowsGridRowWebApp'
import FlowsGridRowiOSApp from './FlowsGridRowiOSApp'
import { useParams } from 'react-router-dom';
import BackToTopButton from '../BackToTopButton/BackToTopButton'
import UserflowFilterSlider from './UserflowFilterSlider'
import FlowsRoundButton from './FlowsRoundButton'

import { selectFilteredUserFlows } from '../../store/slices/UserflowFilterSlice';

const FlowsGridiOSApp = () => {
     
    const dispatch = useDispatch();
    const filteredUserFlows = useSelector(selectFilteredUserFlows);

   // console.log("FlowsGridiOSApp", filteredUserFlows) 

   // const [skip, setSkip] = useState(0);
  //  const limit = 2; // Assuming a fixed limit value   

    //console.log(skip) 


  // State to track if the slider is open or not
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  //console.log("isSliderOpen",isSliderOpen) 

  const sliderRef = useRef(null);

    //getting parameter
    const params = useParams();
    const idfromURL = params.appid
   //console.log("FlowsGridiOSApp idfromURL", idfromURL)


    const limit = useSelector((state) => {
          return state.iosuserflows.limit
       }) 
  // console.log("limit", limit)

 var skip = useSelector((state) => {
          return state.iosuserflows.skip
       }) 
   //console.log("skip", skip)

    const hasMore = useSelector((state) => {
         //console.log("userFlowsByAppId", state.iosuserflows.userFlowByAppID)
          return state.iosuserflows.hasMore
       })  
    //console.log("hasMore", hasMore)

    const appId = useSelector((state) => {
         //console.log("userFlowsByAppId", state.iosuserflows.userFlowByAppID)
          return state.iosuserflows.appId
       })  
    //console.log("appId", appId)


     const userFlowSByAppId = useSelector((state) => {
         //console.log("userFlowsByAppId", state.iosuserflows.userFlowByAppID)
          return state.iosuserflows.userFlowsByAppId
       })  
    // console.log("userFlowSByAppId", userFlowSByAppId)

     const count = useSelector((state) => {
         //console.log("count", state.iosuserflows.userFlowByAppID)
          return state.iosuserflows.count
       }) 

     const isAnyFlowChecked = useSelector(state => state.userflowsfilter.isAnyFlowChecked);

      const appIdOfPreviousUserfilters = useSelector((state) => {
          return state.userflowsfilter.appId
       })  
//console.log("appIdOfPreviousUserfilters", appId)

 useEffect(()=> {
   //console.log("useEffect")
    
    
        if(userFlowSByAppId.length === 0 )//|| userFlowsByAppId.appId !== idfromURL) //if the newflow (idfromURL) is same as old (userFlowsByAppId.appId) then no need to cal API use data from state
        {
            console.log("FETCHING USER FLOWS BY IOS APP ID")
           dispatch(fetchiOSUserFlowByAppId({idfromURL, limit, skip})) 
           // dispatch(fetchiOSUserFlowByAppId({idfromURL})) 
           // setSkip(skip + limit);
            dispatch(fetchiOSAppById(idfromURL))  
           // dispatch(fetchAllUserFlowNamesByAppId(idfromURL)) 
            dispatch(uncheckAllFilters())
        }
        if(userFlowSByAppId.length !== 0 && appId !== idfromURL){
          // console.log("NOT same app ")
            dispatch(resetIOSUserFlowsByAppId())
            dispatch(fetchiOSUserFlowByAppId({idfromURL, limit, skip:0}))
            //dispatch(fetchAllUserFlowNamesByAppId(idfromURL)) //to fetch and reset userflowsfilter slice
            dispatch(uncheckAllFilters())
           // dispatch(fetchiOSUserFlowByAppId({idfromURL}))
            // setSkip(skip + limit); 
        }
        if(userFlowSByAppId.length !== 0  && appIdOfPreviousUserfilters !== idfromURL){
           // console.log("appIdOfPreviousUserfilters NOT same app ID from URL")
            //to reset the filters
            dispatch(uncheckAllFilters())
        }
    },
    [idfromURL]); //this helps reload the page component when url changes from /web/apps/2/flows to /web/apps/1/flows
 //note: you still need to above if condition

 useEffect(() => {
  // Listen for clicks outside of the UserflowFilterSlider
  document.addEventListener('mousedown', handleClickOutside);
  document.addEventListener('touchstart', handleClickOutside);

  return () => {
    // Clean up the event listeners
    document.removeEventListener('mousedown', handleClickOutside);
    document.removeEventListener('touchstart', handleClickOutside);
  };
}, []);

const handleClickOutside = (event) => {
  //console.log("FlowsGridiOSApp handleClickOutside event.target", event.target);
  // Select the FlowsRoundButton by its class name
  const flowsRoundButton = document.getElementById('flowsRoundButton');
  //console.log("FlowsGridiOSApp handleClickOutside FlowsRoundButton", flowsRoundButton);

  // Check if the click is on FlowsRoundButton or its descendants
  if (flowsRoundButton && flowsRoundButton.contains(event.target)) {
   // console.log("FlowsGridiOSApp handleClickOutside FlowsRoundButton asdasdasdasd");
    return; // Do nothing if the click is on FlowsRoundButton
  }

  // Assuming there's a class name for the slider to use for selection
  const slider = document.querySelector('.slider-container');
  // Proceed with the original logic if the click is outside the slider and not on FlowsRoundButton
  if (slider && !slider.contains(event.target)) {
    setIsSliderOpen(false);
  }
};



const handleNextPage = () => {
    //setSkip(skip + limit);
    skip = skip + limit
    dispatch(fetchiOSUserFlowByAppId({idfromURL, limit, skip})) 

  
  };



return (
  <div>
    {
      userFlowSByAppId.length === 0 ? (
      <SimpleLoader />
    ) : (
      <div className="screens-flows-container">
        {/* <FlowsRoundButton isOpen={isSliderOpen} setIsOpen={setIsSliderOpen} /> */}
        {/* {isSliderOpen && <UserflowFilterSlider isOpen={isSliderOpen} setIsOpen={setIsSliderOpen} />} */}
        <FlowsRoundButton isOpen={isSliderOpen} setIsOpen={setIsSliderOpen}/>
        {isSliderOpen && (
          <div className="slider-container" ref={sliderRef}>
          <UserflowFilterSlider isOpen={isSliderOpen} setIsOpen={setIsSliderOpen} />
          </div>
        )}


        {
            !isAnyFlowChecked ? (
                                  <>
                                    <h3 className="ui header">
                                      {count > 1 ? `${count} flows` : `${count} flow`}
                                    </h3>
                                    <FlowsGridRowiOSApp userFlowRows={userFlowSByAppId} />
                                    {hasMore && (
                                      <button className="ui secondary button" onClick={handleNextPage}>
                                        Load More
                                      </button>
                                    )}
                                  </>
                            ) : (
                                    <>
                                    <h3 className="ui header">
                                      {filteredUserFlows.length > 1 ? `${filteredUserFlows.length} flows` : `${filteredUserFlows.length} flow`}
                                    </h3>
                                   <FlowsGridRowiOSApp userFlowRows={filteredUserFlows} />
                                   </>
                             )
          }
      </div>
    )}
    <BackToTopButton />
  </div>
);

};


export default FlowsGridiOSApp






// return (
//     <div>
    
//     { 
      
//       userFlowSByAppId.length === 0? 
//          <SimpleLoader/> : 
//           <div className="screens-flows-container" > 
//           <FlowsRoundButton isOpen={isSliderOpen} setIsOpen={setIsSliderOpen}/>
//           { isSliderOpen ? <UserflowFilterSlider isOpen={isSliderOpen} setIsOpen={setIsSliderOpen}/> : null}
//           <h3 className="ui header">{count > 1 ? count+" flows" : count+" flow"}</h3>
            
//           <FlowsGridRowiOSApp userFlowRows = {userFlowSByAppId}/>
//                 {

//                    !isAnyFlowChecked && hasMore ? 
//                     <button className="ui secondary button" onClick={handleNextPage}>
//                         Load More
//                     </button>
//                     :
//                     null
//                 }
//           </div>
//             }
//         <BackToTopButton/>
//     </div> 
//   );
// };

