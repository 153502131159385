import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')

const userLogInWithEmailAndPassword = createAsyncThunk('user/userLogInWithEmailAndPassword', async({ email, password }) => {
  // console.log("userLogInWithEmailAndPassword thunk")
  // console.log(email)
  // console.log(password)
  
  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          password,
          email
        },
          query: `
          mutation userLogInWithEmailAndPassword($email: String!, $password: String!) {
            userLogInWithEmailAndPassword(email: $email, password: $password) {
              success
              errorMessage
              token
              user {
                email
                hasAccess
                subscription {
                  id
                  planId
                  startDate
                  endDate
                  nextBillingDate
                  planDetails {
                    type
                    name
                    priceText
                    features
                    accessDuration
                  }
                }
              }
            }
        }
        `
      })
  }).then(res => res.json())
})


const validateToken = createAsyncThunk('validateToken/fetch', async({ token }) => {
  //console.log("validateToken thunk")
  //console.log(token)
 
  
  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          token
        },
          query: `
          query ($token: String!) {
          validateToken(token: $token){
            valid
            payload {
              id
            }
          }
        }
        `
      })
  }).then(res => res.json())
})

const changeUserOwnPassword = createAsyncThunk('changeUserOwnPassword/fetch', async({ oldPassword, newPassword }) => {
  //console.log("changeUserOwnPassword thunk")
 // console.log(oldPassword)
  //console.log(newPassword)

  const token = localStorage.getItem('token')

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json",
      'token': token ? `${token}` : '',  // Include the token in the header
       },
      body: JSON.stringify({
          variables: {
          oldPassword,
          newPassword
        },
          query: `
          mutation ChangeUserOwnPassword( $oldPassword: String!, $newPassword: String!  ) {
            changeOwnPassword( oldPassword: $oldPassword, newPassword: $newPassword, ) {
                success
                errorMessage
          }
        }
        `
      })
  }).then(res => res.json())
})


const validateUserTokenAndReturnUser = createAsyncThunk('validateUserTokenAndReturnUser/fetch', async({ token }) => {
  //console.log("validateToken thunk")
  //console.log(token)
 
  
  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          token
        },
          query: `
          query ($token: String!) {
            validateUserTokenAndReturnUser(token: $token) {
              success
              user {
                id
                name
                subscription {
                  id
                  planId
                  startDate
                  endDate
                  nextBillingDate
                  planDetails {
                    type
                    name
                    priceText
                    features
                    accessDuration
                  }
                }
              }
            }
        }
        `
      })
  }).then(res => res.json())
})
export { userLogInWithEmailAndPassword, validateToken , changeUserOwnPassword, validateUserTokenAndReturnUser}
