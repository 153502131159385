const priceRangeList = [
    {
        id: "1",
        name: "$0 - $9.99",
        type: "fixed"
    },
    {
        id: "2",
        name: "$10 - $49.99",
        type: "fixed"
    },
    {
        id: "3",
        name: "$50 - $99.99",
        type: "fixed"
    },
    {
        id: "4",
        name: "$100 - $499.99", 
        type: "fixed"
    },
    {
        id: "5",
        name: "$500 - $999.99",
        type: "fixed"
    },
    {
        id: "6",
        name: "$1000 - $4999",
        type: "fixed"
    },

   ]
   
   
   module.exports = { priceRangeList };