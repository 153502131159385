//enterEmailComponent.js

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserByEmail } from '../../../store'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { websiteName } from '../../../utils/environment'


const EnterEmail = ({ setCurrentStep, email, setEmail }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasError, setHasError] = useState(false);
    const dispatch = useDispatch();
    //const [email, setEmail] = useState('');
    const [errorMessage, setErrorMesssage] = useState('') //Coudn't find your account
   

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsFocused(false);
    };

    // New handleOnBlur function for onBlur event
    const handleOnBlur = () => {
        if (!validateEmail(email) && email !== "") {
            setHasError(true);
            setErrorMesssage("Invalid email format");
        } else {
            setHasError(false);
            setErrorMesssage('');
        }
    };


    // Regular expression for basic email validation
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email.trim());
    };


    const handleOnContinueClick = async (e) => {
        e.preventDefault();
        // Validate email before proceeding
        if (!validateEmail(email) && email !== "") {
            setHasError(true);
            setErrorMesssage("Invalid email format");
            return; // Stop the function if validation fails
        }
        if (email !== "") {
            const result = await dispatch(fetchUserByEmail({email:email})).unwrap();
            const { success, user, errorMessage } = result.data.user;
            if (success) {
                setCurrentStep(2);
                setHasError(false);
            } else {
                setHasError(true);
                setErrorMesssage("Couldn't find your account");
            }
        } else {
            setHasError(true);
            setErrorMesssage("Enter your email");
        }
    };

    const focusStyle = {
        borderRadius: '5px',
        borderWidth: '1px',
        height: '30px',
        borderColor: isFocused ? 'lighgrey' : '#888', // Change borderColor on focus to black
        boxShadow: isFocused ? '0 0 16px rgba(0, 0, 0, 0.1)' : 'none', // Optional: Add shadow on focus
        outline: 'none',
        marginBottom: hasError ? '-1vh' : '4vh'
    };

    return (
        <>

            <div className="first-container" style={{
                height: '100vh', // Make sure the parent container is full viewport height
                display: 'flex',
                justifyContent: 'center', // Center children horizontally in the container
                alignItems: 'center' // Center children vertically in the container
            }}>
                <div style={{
                    border: '1px solid #ccc',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Adjusted to add shadow on all sides
                    //padding: '20px',
                    marginTop: '-10vh', // Move up the form by adjusting the top margin using viewport height
                    marginBottom: '40px', // Increase bottom margin to maintain overall spacing
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '90%', // Use a larger percentage for smaller screens
                    maxWidth: '450px', // Ensure it doesn't get too wide on larger screens
                    borderRadius: '10px' // Added to make corners round
                }}>
                    <h4 style={{ marginBottom: '2px', paddingTop: '30px' }}>Sign in to {websiteName}</h4>
                    <p style={{ fontSize: "1em", color: '#888', marginTop: '2px', marginBottom: '4vh', paddingLeft: '4vw', paddingRight: '4vw', textAlign: 'center' }}>Welcome back! Please sign in to continue</p>
                    <form className="ui form" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left', // Center horizontally in a column flex container
                        justifyContent: 'left', // Center vertically in a column flex container
                        width: '60%' // Use the full width of the parent container
                    }}>
                        <label htmlFor="inputField" style={{ fontSize: '13px' }}>Email address</label>
                        <input
                            type="email"
                            id="inputField"
                            name="email"
                            value={email}
                            // onBlur={handleEmailChange}
                            // onChange={(e) => setEmail(e.target.value)}
                            onChange={handleEmailChange}
                            onBlur={handleOnBlur} 
                            required
                            style={{
                                ...focusStyle,
                                fontFamily: 'Lato',
                                fontSize: '14px',
                                borderColor: hasError ? '#ff4d4d' : '#888'
                            }}
                            onFocus={() => setIsFocused(true)}
                        />
                        {hasError ? <p style={{
                            fontSize: '12px',
                            marginBottom: '4vh',
                            color: '#ff4d4d'
                        }}>{errorMessage}</p> : null}
                        <button type="submit" style={{ height: '30px', background: 'linear-gradient(to top, #000000, #555555)', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '3vh' }} onClick={handleOnContinueClick}>
                            Continue &#9656;
                        </button>
                    </form>
                    <div className="second-contained" style={{ marginTop: '10px', width: '100%', backgroundColor: '#f9f9f9', border: '1px solid #dcdcdc', padding: '20px', borderRadius: '0 0 5px 5px', textAlign: 'center' }}>
                        <p style={{ fontSize: "0.9em", color: '#888', marginTop: '1px' }}>
                            Don't have an account? <Link to="/signup" style={{ color: '#333', cursor: 'pointer', textDecoration: 'none' }}>Sign up</Link>
                        </p>
                    </div>
                </div>

            </div>


        </>
    );
}

export default EnterEmail;