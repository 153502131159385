// App.js
import { BrowserRouter, MemoryRouter, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validateUserTokenAndReturnUser, setAuthLoading } from './store'

import React, { useEffect } from 'react';

import CleanLandingPage from './components/CleanLandingPage/CleanLandingPage';
import LandingPageWithHeroSections from './components/CleanLandingPage/LandingPageWithHeroSections';
import WebAppsLandingPage from './components/WebAppsLandingPage/WebAppsLandingPage';
import IOSLandingPage from './components/IOSLandingPages/IOSLandingPage';
import WebAppsFlowsPage from './components/WebAppDetailPages/WebAppsFlowsPage';
import IOSAppsFlowsPage from './components/iOSAppDetailPages/IOSAppsFlowsPage';
import Header from './components/Header';
import Header2 from './components/Header2';
import SearchBarTopSection from './components/SearchBarTopSection';
import FlowsDetailsLandingPage from './components/WebFlowDetailsPages/FlowDetailsLandingPage'
import IOSFlowsDetailsLandingPage from './components/iOSFlowDetailsPages/IOSFlowDetailsLandingPage'
import NotFound404Page from './components/ErrorPages/NotFound404Page'
import SearchBarHeader from './components/SearchBarHeader/SearchBarHeader'
import WorkingOnItPage from './components/InfoPages/WorkingOnItPage'
import RequestContentPage from './components/RequestContentPages/RequestContentPage'
import LoginPage from './components/Login/LoginPage'
import SignUpWithEmailAndPassword from './components/SignUp/SignUpWithEmailAndPassword'
import LogInWithEmailAndPassword from './components/Login/LogInWithEmailAndPassword'
import PricingPage from './components/PricingPages/PricingPage'
import SimpleLoader from './components/Loaders/SimpleLoader';
import TermsOfService from './components/Policies/termsOfService';
import PrivacyPolicy from './components/Policies/privacyPolicy';
import ProfilePage from './components/UserProfilePages/ProfilePage';
import ProtectedRoute from './components/ProtectedRoute';
import posthog from 'posthog-js';
import { isProduction } from './utils/environment';
import MultistepLogInWithEmailAndPassword from './components/Login/MultistepLogInWithEmailAndPassword';
import MultistepSignUp from './components/SignUp/MultistepSignUp';
import CompareFlowsPage from './components/CompareFlowsPages/CompareFlowsPage';
import EmailUsToRegister from './components/SignUp/EmailUsToRegister';

const App = () => {

  //initialize posthog
  if (isProduction) {
   // console.log("Production, Initializing Posthog...3")
    posthog.init('phc_siXdqCsGyXncDojPqHrMguG0kkNLvHfL6JqoQ0Mvhb0',
      {
        api_host: 'https://us.i.posthog.com',
        autocapture: true, // Disable automatic event capturing
        person_profiles:  'always', //identified_only' or 'always' to create profiles for anonymous users as well
      }
    )
  }
  
  const dispatch = useDispatch();

  //NOTE: this is the first thing that runs when the app starts
  // or when the page is refreshed. isAuthLoading purpose is to show a loader
  // while the app is checking if the user is authenticated or not
  const { isAuthLoading } = useSelector((state) => state.auth)

  useEffect(() => {

    // fetch the token from localStorage
    const token = localStorage.getItem('token');
    //console.log("Token?", token)

    // If a token exists, dispatch an action to validate it and get user data
    if (token) {
      dispatch(validateUserTokenAndReturnUser({ token }));

    } else {
      // If there is no token, set isAuthLoading to false as we don't want to show the loader in App.js
      dispatch(setAuthLoading(false));
    }



  }, [dispatch]);


  if (isAuthLoading) {
    return (
      <SimpleLoader />
    )
  }


  return (
    <div>
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<LandingPageWithHeroSections />} />
          {/* <Route path="/pricing" element={<PricingPage />} /> */}
          <Route path='/signin' element={<MultistepLogInWithEmailAndPassword />} />
          <Route path='/signup' element={<EmailUsToRegister />} />
          <Route path="/browse/web/apps" element={<WebAppsLandingPage />} /> //currently the app starts here
          <Route path="/browse/ios/apps" element={<IOSLandingPage />} />
          <Route path="/web/apps/:id/flows" element={<WebAppsFlowsPage />} />
          <Route path="/ios/apps/:appid/flows" element={<IOSAppsFlowsPage />} />
          <Route path="/web/apps/flows/:id" element={<FlowsDetailsLandingPage />} />
          <Route path="/ios/apps/flows/:id" element={<IOSFlowsDetailsLandingPage />} />
          <Route path="/compare/flows/:topflowid/:bottomflowid" element={<CompareFlowsPage />} />
          <Route path='/profile/*' element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
          <Route path="/policies/terms-of-service" element={<TermsOfService />} />
          <Route path="/policies/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/onit" element={<WorkingOnItPage />} />
          <Route path="/404" element={<NotFound404Page />} />
          <Route path="*" element={<NotFound404Page />} />
        </Routes>

      </BrowserRouter>

    </div>
  );
};

export default App;

//<Route path="/login"  element={<LoginPage />}/> 
//<Route path="/login" element={<LogInWithEmailAndPassword />} />