//selectedAppSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { fetchiOSAppById } from '../thunks/iOSAppsThunk'
import { fetchIOSUserFlowById } from '../thunks/userFlowThunk'

import { fetchWebAppById } from '../thunks/webAppsThunk'
import { fetchUserFlowById } from '../thunks/userFlowThunk'
import { logout } from './UserAuthSlice'
import { resetOtherStateAfterLogin } from './UserAuthSlice'


const initialState = {
   app:{},  
}

const selectedAppSlice = createSlice({
	name:"app",
	initialState,
	reducers: {
		selectedApp: (state, action)=> {
			state.app = action.payload
		}
	},
	extraReducers(builder){
		builder.addCase(fetchiOSAppById.fulfilled, (state, action) => {
        //console.log(action.payload.data.appById)
       state.app = action.payload.data.appById //updating the selectedApp
       
    });
    builder.addCase(fetchIOSUserFlowById.fulfilled, (state, action) => {
       // console.log("selectedAppSlice  fetchIOSUserFlowById", action.payload.data)
       state.app = action.payload.data.userFlowId.app //updating the selectedApp
    });
    builder.addCase(fetchWebAppById.fulfilled, (state, action) => {
        //console.log(action.payload.data.appById)
       state.app = action.payload.data.appById //updating the selectedApp
    });
    builder.addCase(fetchUserFlowById.fulfilled, (state, action) => {
       // console.log(" selectedAppSlice fetchUserFlowById", action.payload.data)
       state.app = action.payload.data.userFlowId.app //updating the selectedApp
    });
    builder.addCase(logout, (state, action) => {
		//console.log("logout")
		return initialState
	})
   builder.addCase(resetOtherStateAfterLogin, (state, action) => {
      //console.log("resetOtherStateAfterLogin")
      return initialState
   })
	}
})


export const { selectedApp } = selectedAppSlice.actions;
export const selectedAppReducer = selectedAppSlice.reducer;