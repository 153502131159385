// UserflowFilterSlider.js
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllUserFlowNamesByAppId, toggleFilter } from '../../store'
import { useParams } from 'react-router-dom';
import SimpleLoader from '../Loaders/SimpleLoader'
import { changeSearchTerm } from '../../store/slices/UserflowFilterSlice'


const UserflowFilterSlider = ({ isOpen, setIsOpen }) => {
const dispatch = useDispatch();


//getting parameter
    const params = useParams();
    const idfromURL = params.appid
   //console.log("UserflowFilterSlider idfromURL", idfromURL)


const userflows = useSelector((state) => {
          return state.userflowsfilter.filteredUserFlows
       }) 
//console.log("userflows", userflows)

const searchTerm = useSelector((state) => {
      return state.userflowsfilter.searchTerm
   })



const filters = useSelector(state => state.userflowsfilter.filters);
//console.log("filters", filters)


const isAnyFlowChecked = useSelector(state => state.userflowsfilter.isAnyFlowChecked);
//console.log("isAnyFlowChecked", isAnyFlowChecked)

 const appId = useSelector((state) => {
          return state.userflowsfilter.appId
       })  
//console.log("appId", appId)

 const isLoading = useSelector((state) => {
          return state.userflowsfilter.isLoading
       })  
//console.log("isLoading", isLoading)

//  const iOSApp = useSelector((state) => {
//   //console.log(state.iosapps.selectediOSApp)
//   return state.iosapps.selectediOSApp
// })

 const iOSApp = useSelector((state) => {
  //console.log(state.selectedApp.app)
  return state.selectedApp.app
})
//console.log("iOSApp", iOSApp.name)


useEffect(() => {

    if(appId !== idfromURL){
      //console.log("FETCHING USER FLOWS NAMES BY IOS APP ID")
      dispatch(fetchAllUserFlowNamesByAppId(idfromURL))
    } 
  }, [idfromURL]);



const handleCloseSlider = (r) => {   
        const id = r.target.id;
         setIsOpen(false)
    };  


const handleCheckboxChange = (userFlowId) => (event) => {
      const { checked } = event.target;
      dispatch(toggleFilter(userFlowId));
    };  
    
    
const handleSearchTermChange = (event) => { 
    const newTerm = event.target.value;
      dispatch(changeSearchTerm(newTerm))
    };      
      

return (
    <div className="ui vertical right visible sidebar menu">
                      <h3 className="item">
                          {iOSApp.name}'s Flows
                      </h3>

                     <div id="search" className="ui search ">
                     <div className="ui icon input">
                       <input className=" prompt search-bar-for-flows-dropdown" 
                       type="text" 
                       style = {{color: "black"}} 
                       placeholder={"Search "+iOSApp.name+ " flows..."} 
                       value={searchTerm}
                       onChange={handleSearchTermChange}
                       //onBlur={handleBlur}
                       />
                          <i className="search icon"></i>
                      
                    </div>
                    </div>
                    <div className="ui divider"></div>

                     {
                        isLoading? 
                        <SimpleLoader/> : 
                        <>
                        { isAnyFlowChecked && 
                        <>
                              {Object.entries(filters).filter(([id, isChecked]) => isChecked).map(([id, _]) => {
                                const matchedCategory = userflows.find(userflow => userflow.id === id);
                                return matchedCategory ? (
                                  <div className="item" key={id}>
                                    <div className="ui checkbox">
                                      <input 
                                        type="checkbox" 
                                        checked={true}
                                        onChange={handleCheckboxChange(id)}
                                      />
                                      <label>{matchedCategory.name}</label>
                                    </div>
                                  </div>
                                ) : null;
                              })}
                              <br/>
                         </>
                         }
                         { userflows.map(userflow => (
                            <div className="item" key={userflow.id}>
                               <div className="ui checkbox">
                                  <input 
                                  type="checkbox" 
                                  name="example"
                                  checked={filters[userflow.id] || false}
                                  onChange={handleCheckboxChange(userflow.id)}

                                  />
                                  <label>{userflow.name}</label>
                                </div>
                             </div>
                        ))}
                        
                        </>
                     }
                  
  </div> 
  );
};


export default UserflowFilterSlider






