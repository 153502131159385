// UserflowSuggestionList.js
import React from 'react';
import { useDispatch } from 'react-redux';
import {selectSearchSuggestion, fetchWebAppByName} from '../../../store'
import { useNavigate } from 'react-router-dom';




const UserflowSuggestionList = ({suggestions, typeofSearch, handleSuggestionClick}) => {
 //console.log(suggestions);
  //console.log("UserflowSuggestionList - typeofSearch", typeofSearch);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //console.log("UserflowSuggestionList",suggestions)

   
  const suggestionClicked = (r) => {   
    const selectedSuggestion = r;
    //console.log("selectedSuggestion", selectedSuggestion)
    handleSuggestionClick(selectedSuggestion)
     
    };

  return (
  <div className="search-result-list">
     {suggestions.map((result, i) => (
            <div className="item" onMouseDown={() => suggestionClicked(result)} key={i} style={{ display: 'flex', alignItems: 'left' }}>
              {/* <img className="ui image" src={result.logo_url} alt={result.name} style={{ marginRight: '10px' }} /> */}
              <div>
                <strong style={{ display: 'block', marginBottom: '5px' }}>{result.name}</strong>
                <div style={{ color: '#888' }}>{result.__typename}</div>
              </div>
            </div>
          ))}
    </div>
  );
};


export default UserflowSuggestionList







