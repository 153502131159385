// FlowsRoundButton.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { selectCheckedCount } from '../../store/slices/UserflowFilterSlice';
import { uncheckAllFilters} from '../../store'


const FlowsRoundButton = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  //console.log("FlowsRoundButton isOpen", isOpen)

 const isAnyFlowChecked = useSelector(state => state.userflowsfilter.isAnyFlowChecked);
 const checkedCount = useSelector(selectCheckedCount);
//console.log(checkedCount)


  const handleToggleSlider = (r) => {   
        const id = r.target.id;
      setIsOpen(!isOpen); 
           
    };  

  const handleUncheckAllFilters = (r) => {   
        const id = r.target.id;
       //console.log("handleUncheckAllFilters")
       dispatch(uncheckAllFilters())
    };  



  return (
  //asdasdasd
  <button id="flowsRoundButton" 
  className={isAnyFlowChecked ? " secondary ui button  circular" : " secondary basic ui  button  circular"}
                     onClick={handleToggleSlider}>
                               {
                                isAnyFlowChecked ? 
                                <i 
                                    className={"times icon"}
                                    onClick={(e) => {
                                        e.stopPropagation(); // This stops the click event from propagating to the button
                                        handleUncheckAllFilters(e);
                                    }}
                                ></i>
                                :
                                <i 
                                    className={isOpen ? "angle up icon" : "angle down icon"}
                                    onClick={(e) => {
                                        e.stopPropagation(); // This stops the click event from propagating to the button
                                        handleUncheckAllFilters(e);
                                    }}
                                ></i>
                               }

                              

                            {isAnyFlowChecked ? "Flows ("+checkedCount+")" : 'Flows'}
    </button> 
  );
};


export default FlowsRoundButton






