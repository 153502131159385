// TopMenuForProfile.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const TopMenuForProfile = () => {

  const { user } = useSelector((state) => state.auth)

  const subscription = user.subscription;



  const [activeButton, setActiveButton] = useState("password");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="ui" style={{ paddingTop: '2em' }}>
      <div className="product-insight-horizontal-scrollable-list-container">
        <div className="ui link horizontal list">

          <Link to="password">
            <button
              onClick={() => handleButtonClick('password')}
              className={activeButton === "password" ? " secondary ui  button  circular " : " secondary basic  ui  button  circular"}
            >Password</button>
          </Link>

          {subscription && Object.keys(subscription).length > 0 && (
            <Link to="plan-and-billing">
              <button
                onClick={() => handleButtonClick('plan-and-billing')}
                className={activeButton === "plan-and-billing" ? "secondary ui button circular" : "secondary basic ui button circular"}
              >
                Plan & Billing
              </button>
            </Link>
          )}

        </div>
      </div>


    </div >

  );
};


export default TopMenuForProfile





