//WebAppsSlice.js

import { createSlice } from '@reduxjs/toolkit'
import {filteredApps,  fetchInitialWebApps, fetchWebAppById, fetchProductInsightForWebApp, fetchWebCompetitorsByAppId } from '../thunks/webAppsThunk'
import { fetchUserFlowById } from '../thunks/userFlowThunk'
import { logout } from './UserAuthSlice'
import { resetOtherStateAfterLogin } from './UserAuthSlice'

const initialState = {
	initialWebApps:[],
	filteredApps: [],
	hasMore: false,
	limit: 10,
	//selectedWebApp: {}, 
	productInsight: {}
}
const WebAppsSlice = createSlice({
	name:"webapps",
	initialState,
	reducers: {
		selectedWebApp: (state, action)=> {
			//..state.selectedWebApp = action.payload
		}
	},
	extraReducers(builder){
		builder.addCase(fetchInitialWebApps.fulfilled, (state, action) => {
        //console.log(action.payload.data.webapps)
      state.initialWebApps = action.payload.data.webapps //updating the webapps
    });
		builder.addCase(fetchWebAppById.fulfilled, (state, action) => {
        //console.log(action.payload.data.appById)
      // state.selectedWebApp = action.payload.data.appById //updating the selectedWebApp
       //state.productInsight = action.payload.data.appById.productInsight // setting productInsight
    });
    builder.addCase(fetchUserFlowById.fulfilled, (state, action) => {
       // console.log("WebAppsSlice  fetchUserFlowById", action.payload.data)
      // state.selectedWebApp = action.payload.data.userFlowId.app //updating the selectedWebApp
    });	
     builder.addCase(fetchProductInsightForWebApp.fulfilled, (state, action) => {
       // console.log("WebAppsSlice  fetchProductInsightForWebApp", action.payload.data.productInsightAppById)
	   state.productInsight = {
		...state.productInsight,
		...action.payload.data.productInsightAppById
	  }
    });	
	builder.addCase(logout, (state, action) => {
		//console.log("logout")
		return initialState
	})
	builder.addCase(resetOtherStateAfterLogin, (state, action) => {
		//console.log("resetOtherStateAfterLogin")
		return initialState
	})
	builder.addCase(fetchWebCompetitorsByAppId.fulfilled, (state, action) => {
		//console.log("fetchWebCompetitorsByAppId", action.payload.data.appById)
		const {id, competitors} = action.payload.data.appById
		state.productInsight = {
			...state.productInsight,
			competitors
		}
	})
	builder.addCase(filteredApps.fulfilled, (state, action) => {
		//console.log("filteredApps in webAppsSlice", action.payload.data.filteredApps)
		const apps = action.payload.data.filteredApps
		//filter out the webapps by checking the type
		const webapps = apps.filter(app => app.__typename === "Web")
		//console.log("filtered webapps", webapps)
		state.filteredApps = webapps
	})
}
	
})


export const { selectedWebApp } = WebAppsSlice.actions;
export const webappsReducer = WebAppsSlice.reducer;