// store/index.js
//Redux Store
import {configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query'
import { searchApi } from './apis/searchApi'
import {searchReducer, changeSearchTerm, selectSearchSuggestion, updateSearchedApp} from './slices/SearchSlice';
import { webappsReducer, selectedWebApp } from './slices/WebAppsSlice'
import { iOSappsReducer, selectediOSApp } from './slices/iOSAppsSlice'
import { selectedAppReducer, selectedApp } from './slices/SelectedAppSlice'
import { useFlowReducer, updateSelectedFlow, resetUserFlowsByAppId } from './slices/UserFlowSlice'
import { iOSFlowReducer, updateSelectedIOSFlow, resetIOSUserFlowsByAppId} from './slices/iOSUserFlowSlice'
import { userflowfilterReducer, toggleFilter, uncheckAllFilters} from './slices/UserflowFilterSlice'
import { appCategoriesfilterReducer, toggleAppCategoryFilter, uncheckAllAppCategoryFilters } from "./slices/AppCategoriesFilterSlice";
import { appMonetizationsfilterReducer, toggleAppMonetizationFilter, uncheckAllAppMonetizationFilters } from "./slices/AppMonetizationsFilterSlice";
import { appPricingsfilterReducer, toggleAppPricingFilter, uncheckAllAppPricingFilters } from "./slices/AppPricingsFilterSlice";
import { userAuthReducer, logout, resetOtherStateAfterLogin, setAuthLoading } from './slices/UserAuthSlice'
import { addCategory, removeCategory, addMonetization , removeMonetization, searchfilterReducer, addPriceRange, removePriceRange } from "./slices/SearchFilterSlice";


const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production', //to disbale redux devtools in  prod
    reducer: {
        search: searchReducer,
       // [searchApi.reducerPath]: searchApi.reducer,
        auth: userAuthReducer,
        selectedApp: selectedAppReducer,
        webapps: webappsReducer,
        userflows: useFlowReducer,
        iosapps: iOSappsReducer,
        iosuserflows:iOSFlowReducer,
        userflowsfilter:userflowfilterReducer,
        appcategoriesfilter:appCategoriesfilterReducer,
        appmonetizationsfilter:appMonetizationsfilterReducer,
        apppricingsfilter:appPricingsfilterReducer,
        searchfilter:searchfilterReducer
    },

// Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(searchApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

//Exporting actions
export  { store, 
          toggleFilter, 
          uncheckAllFilters, 
          changeSearchTerm, 
          selectSearchSuggestion, 
          selectedWebApp, 
          updateSelectedFlow, 
          resetUserFlowsByAppId, 
          selectediOSApp, 
          updateSelectedIOSFlow, 
          resetIOSUserFlowsByAppId, 
          selectedApp,
          logout,
          resetOtherStateAfterLogin,
          setAuthLoading ,
          toggleAppCategoryFilter, 
          uncheckAllAppCategoryFilters ,
          addCategory,
          removeCategory,
          addMonetization,
          removeMonetization,
          toggleAppMonetizationFilter, 
          uncheckAllAppMonetizationFilters,
          toggleAppPricingFilter,
          addPriceRange, removePriceRange, 
        };

//Exporting thunks
export { useSearchSuggestionsQuery } from './apis/searchApi'
export { fetchUserById , fetchUserByEmail}  from './thunks/userThunk'
export { userLogInWithEmailAndPassword, validateToken, changeUserOwnPassword, validateUserTokenAndReturnUser } from './thunks/authThunk'
export {  fetchSearchSuggestions, 
          fetchGreetingFromGraphQL 
        } from './thunks/searchThunk' //importing an exporting search thunk

export {  fetchInitialWebApps, 
          fetchWebAppById, 
          fetchWebAppByName, 
          fetchProductInsightForWebApp ,
          fetchWebCompetitorsByAppId,
          fetchAllAppPricings
        } from './thunks/webAppsThunk' //importing an exporting web apps thunk

export {  fetchInitialiOSApps, 
          fetchiOSAppById, 
          fetchProductInsightForiOSApp ,
          fetchIOSCompetitorsByAppId
        } from './thunks/iOSAppsThunk' //importing an exporting ios apps thunk

export {  fetchAllUserFlowNamesByAppId, 
          fetchUserFlowByAppId, 
          fetchiOSUserFlowByAppId, 
          fetchUserFlowById, 
          fetchIOSUserFlowById 
        } from './thunks/userFlowThunk' //importing an exporting user flow thunk












