// AppFilters.js
//This is the parent component of AppCategoryFilter.js and other filters to be added in future
//each of these filters will be a horizontal list of buttons which will be scrollable
//up to the widht of the screen. On click of each button, each will have it's own suggestions 
//will be shown below the button.
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppCategoryFilter from './AppCategories/AppCategoryFilter'
import AppMonetizationFilter from './AppMonetizations/AppMonetizationFilter';
//import SuggestionButton from './SuggestionButton';
import { filteredApps } from '../../store/thunks/webAppsThunk';
import CompareFlowsButton from './CompareFlowsButton';
import AppPricingFilter from './AppPricings/AppPricingFilter';


const AppFilters = () => {
  const dispatch = useDispatch();
  const { categories, monetizations, priceRanges } = useSelector(state => state.searchfilter);
  useEffect(() => {
   
    if (categories.length > 0 || monetizations.length > 0 || priceRanges.length > 0) {
      dispatch(filteredApps());
      //console.log("change in categories or monetizations; fecching filtered apps")
    }
  }, [categories, monetizations, priceRanges, dispatch]);

  return (
    <div className="ui vertical stripe">
      <h3 style={{color: '#888'}}>Filters</h3>
     
      <div className="filters-container" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
      <div>
      <CompareFlowsButton />
      </div>
      <div>
      <AppCategoryFilter />
      </div>
      <div>
      <AppMonetizationFilter />
      </div>
      <div>
      <AppPricingFilter />
     
      </div>
      

      </div>
    </div> 
  );
};

export default AppFilters

//<SuggestionButton categoryName="App Categories" suggestions={[" 1", " 2", "3", "3", " 3", " 1", " 2", "3", "3", " 3", " 1", " 2", "3", "3", " 3", " 1", " 2", "3", "3", " 3", " 1", " 2", "3", "3", " 3", " 1", " 2", "3", "3", " 3", " 1", " 2", "3", "3", " 3", " 1", " 2", "3", "3", " 3", " 1", " 2", "3", "3", " 3", " 1", " 2", "3", "3", " 3", " 1", " 2", "3", "3", " 3", " 1", " 2", "3", "3", " 3"]} /> 
