// WebAppsResultGrid.js
import React from 'react';
import { useDispatch } from 'react-redux';
import {selectedWebApp, selectedApp} from '../../store'
import { useNavigate } from 'react-router-dom';




const WebAppsResultGrid = ({ webapps }) => {
 // console.log(webapps);
  //console.log("WebAppsResultGrid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  //console.log("WebAppsResultGrid",webapps)

   
  const handleOnClick = (r) => {   
    const webappclicked = r;
    //console.log(webappclicked);
    if (webappclicked.isBlurred) {
        navigate('/signin');
    } else {
        dispatch(selectedApp(webappclicked))
        navigate(`/web/apps/${webappclicked.id}/flows`);
    }

    dispatch(selectedApp(webappclicked))
    };

     // Truncate function
     function truncate(str, num) {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + '...';
    }
    

  return (
   <div className="doubling stackable four column row" >
     {webapps.map((result, i) => (

            <div className="column" key={i} style={{ marginBottom: '1.5em' }}>
                     <div onClick={()=> handleOnClick(result)} style={{ cursor: 'pointer' }}>
                       
                        <img className={result.isBlurred ? 'blurred ui image webapps-images' : 'ui image webapps-images'}
                        src={result.initial_image_url}/>   
                       
                       <div className="ui unstackable items">
                               <div className=" item">
                                   <div className="ui mini image">
                                            
                                          <img  src={result.logo_url}/>   
                                   </div>
                                   <div className="content">
                                          <div className="header">{truncate(result.name, 20)}</div>
                                          <div className="extra">
                                                {result.description}
                                          </div>

                                   </div>
                              </div>
                        </div>    
                    </div>      
                </div>

          ))}
    </div>
  );
};


export default WebAppsResultGrid








