// PasswordReset.js
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import {changeUserOwnPassword} from '../../store/thunks/authThunk'


const PasswordReset = () => {
    const [ currentPassword, setCurrentPassword ] = useState("");
    const [ newPassword, setNewPassword ] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const dispatch = useDispatch();

    const handleResetFormFeilds = (e) => {
        e.preventDefault();
        setCurrentPassword("");
        setNewPassword("");
    }
    
    const isFomValid = () => {  
        return currentPassword.length > 0 && newPassword.length > 0 && newPassword.length >= 6;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        console.log("Current Password: ", currentPassword);
        console.log("New Password   : ", newPassword)
        const res = await dispatch(changeUserOwnPassword({oldPassword: currentPassword, newPassword: newPassword}))
        console.log(res.payload.data.changeOwnPassword)
        const {success, errorMessage} = res.payload.data.changeOwnPassword;
       // console.log(errorMessage)
        if(success){
            setMessage("Password Changed Successfully")
        }else{
            setMessage("Password Change Failed. "+ errorMessage)
        }   
        setIsLoading(false)
        setCurrentPassword("");
        setNewPassword("");
    }

    return (
        <div className="ui segment">
            <div >
                <div className="ui text container">
                    <div style={{ marginTop: "5vh", marginBottom: "10vh" }}>
                        <h2 className="ui header">
                            Reset Password
                            <div className="sub header">Password needs to be at least 6 characters long.</div>
                        </h2>
                        <br />
                        <form className={isLoading ? "ui loading form" : "ui form"}>
                            <input type="text" name="username" autoComplete="username" style={{ display: 'none' }} />
                            <div className="two fields">
                                <div className=" field">
                                    <h4>
                                        Current Password
                                    </h4>
                                    <input 
                                        type="password" 
                                        value={currentPassword} 
                                        onChange={(e) => setCurrentPassword(e.target.value)} 
                                        placeholder="Current Password" 
                                        autoComplete="current-password" 
                                    />
                                </div>
                                <div className="field">
                                <h4>
                                        New Password
                                    </h4>
                                    <input 
                                        type="password" 
                                        value={newPassword} 
                                        placeholder="New Password" 
                                        autoComplete="new-password" 
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            {message.length >0  && <p style={ {fontSize: "1em", color: '#888' } }>{message}</p>}      
                            <div >
                                <button disabled={!isFomValid()} className="ui secondary circular button" type="submit" onClick={handleSubmit}>Save</button>
                              {(newPassword.length > 0 || currentPassword.length >0) ?  <button className='ui circular button' onClick={handleResetFormFeilds}> Reset</button> : null}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default PasswordReset





