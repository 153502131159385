//UserAuthSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { userLogInWithEmailAndPassword, validateToken , validateUserTokenAndReturnUser} from '../../store'
import keys from '../../config/keys'

const initialState = {
	isLoading: false,
	isAuthenticated: false,
	isAuthLoading: true, // only used in App.js to prevent flicker any page before token validation
	token: null,
	user: null,
	userId: null,
	errorMessage: '',
	hasError: false,
	scopes: [],
}

const UserAuthSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state, action) => {
			//console.log("inside logout action")
			// Remove the persisted token from localStorage
			localStorage.removeItem('token');
			//return initialState; // Reset the state to initial values
			// Explicitly set isAuthLoading to false after logout
			return {
				...initialState,
				isAuthLoading: false,
			};
		},
		resetOtherStateAfterLogin: () => { },
		// Reset the isLoading state to false if there is no token
		setAuthLoading: (state, action) => {
			state.isAuthLoading = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(userLogInWithEmailAndPassword.pending, (state, action) => {
			state.isLoading = true;
			state.hasError = false;
		})
		builder.addCase(userLogInWithEmailAndPassword.fulfilled, (state, action) => {
			state.isLoading = false;
			const { success, errorMessage, user, token } = action.payload.data.userLogInWithEmailAndPassword;
			if (success) {
				if (user.hasAccess) {
					state.isAuthenticated = true;
					state.user = user;
					state.token = token;
					state.userId = user.id;
					state.errorMessage = '';
					state.hasError = false;
					localStorage.setItem('token', token); // Persist the token in localStorage
				} else {
					state.errorMessage = 'Sorry, your account does not currently have access. Please verify your login or consider signing up.';
					state.hasError = true;
					state.isAuthenticated = false;
				}
			} else {
				state.errorMessage = errorMessage;
				state.hasError = true;
				state.isAuthenticated = false;
			}
		})
		builder.addCase(validateUserTokenAndReturnUser.pending, (state, action) => {
			//state.isLoading = true
			state.isAuthLoading = true
		});
		builder.addCase(validateUserTokenAndReturnUser.fulfilled, (state, action) => {
			const { success, user} = action.payload.data.validateUserTokenAndReturnUser
			//console.log("user", user)
			//console.log("success", success)

			if (success) {
				state.isAuthenticated = true
				state.userId = user.id
				state.user = user
			} else {
				state.isAuthenticated = false
				state.user = null
				localStorage.removeItem('token')
			}

			//state.isLoading = false
			state.isAuthLoading = false
		});


	}
})


export const { logout, resetOtherStateAfterLogin, setAuthLoading } = UserAuthSlice.actions;
export const userAuthReducer = UserAuthSlice.reducer;