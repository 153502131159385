  //AppCategoriesFilterSlice.js

  import { createSlice } from '@reduxjs/toolkit'
  import {fetchAllAppCategories } from '../thunks/webAppsThunk'
  import { createSelector } from 'reselect';
  import { logout } from './UserAuthSlice';
  import { resetOtherStateAfterLogin } from './UserAuthSlice';
  
  const initialState = {
    searchTerm: '',
    isLoading: false,
    appCategories: [], // This will be populated from the API
    filteredAppCategories: [], // This will hold the filtered set based on the searchTerm
    filters: {}, // Object to track the state of each filter
    isAnyFlowChecked: false // Boolean attribute
  }
  
  const AppCategoriesFilterSlice = createSlice({
    name: 'appcategoriesfilter',
    initialState,
    reducers: {
     changeSearchTerm: (state, action) => {
        state.searchTerm = action.payload
        if (action.payload.trim() === '') {
            state.filteredAppCategories = state.appCategories;
        } else {
          // Otherwise, filter based on the search term
          state.filteredAppCategories = state.appCategories.filter(category =>
            category.name.toLowerCase().includes(action.payload.toLowerCase())
          );
        }
      }, 
     toggleAppCategoryFilter: (state, action) => {
          //console.log("toggleAppCategoryFilter", action.payload)
          const appCategoryID = action.payload;
          state.filters[appCategoryID] = !state.filters[appCategoryID];
  
          // Update isAnyFlowChecked
          state.isAnyFlowChecked = Object.values(state.filters).some(isChecked => isChecked);
      },
     uncheckAllAppCategoryFilters: (state) => {
        state.appCategories.forEach(category => {
            state.filters[category.id] = false;
            });
        state.isAnyFlowChecked = false;
  
     }, 
  
    },
    extraReducers(builder){
      builder.addCase(fetchAllAppCategories.fulfilled, (state, action) => {
          //console.log(action)
          //console.log(action.meta)
         state.appCategories = action.payload.data.allCategories.categories
         state.filteredAppCategories = action.payload.data.allCategories.categories
         state.filters = {}
         state.isAnyFlowChecked = false
         state.isLoading = false; // Set isLoading to false once the data is fetched
      });
      builder.addCase(fetchAllAppCategories.pending, (state) => {
                state.isLoading = true;
      });
      builder.addCase(logout, (state, action) => {
        //console.log("logout")
        return initialState
      })
      builder.addCase(resetOtherStateAfterLogin, (state, action) => {
        //console.log("resetOtherStateAfterLogin")
        return initialState
      })
      
    }
  })
  
  // Selector to count the number of checked userflows
  export const selectCheckedCount = (state) => {
      return Object.values(state.appcategoriesfilter.filters).filter(isChecked => isChecked).length;
  };
  

  const getAppCategories = state => state.appcategoriesfilter.appCategories;
  const getFilters = state => state.appcategoriesfilter.filters;
  
  //memoized selector
    export const selectFilteredAppCategories = createSelector(
    [getAppCategories, getFilters],
    (appCategories, filters) => appCategories.filter(category => filters[category.id])
    );
  
  
  // Action creators are generated for each case reducer function
  export const { toggleAppCategoryFilter, uncheckAllAppCategoryFilters, changeSearchTerm } = AppCategoriesFilterSlice.actions
  
  export const appCategoriesfilterReducer = AppCategoriesFilterSlice.reducer;