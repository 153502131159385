  //AppMonetizationsFilterSlice.js

  import { createSlice } from '@reduxjs/toolkit'
  import {fetchAllAppMonetizations } from '../thunks/webAppsThunk'
  import { createSelector } from 'reselect';
  import { logout } from './UserAuthSlice';
  import { resetOtherStateAfterLogin } from './UserAuthSlice';
  
  const initialState = {
    searchTerm: '',
    isLoading: false,
    appMonetizations: [], // This will be populated from the API
    filteredAppMonetizations: [], // This will hold the filtered set based on the searchTerm
    filters: {}, // Object to track the state of each filter
    isAnyFlowChecked: false // Boolean attribute
  }
  
  const AppMonetizationsFilterSlice = createSlice({
    name: 'appmonetizationsfilter',
    initialState,
    reducers: {
     changeSearchTerm: (state, action) => {
        state.searchTerm = action.payload
        if (action.payload.trim() === '') {
            state.filteredAppMonetizations = state.appMonetizations;
        } else {
          // Otherwise, filter based on the search term
          state.filteredAppMonetizations = state.appMonetizations.filter(monetization =>
            monetization.name.toLowerCase().includes(action.payload.toLowerCase())
          );
        }
      }, 
     toggleAppMonetizationFilter: (state, action) => {
          //console.log("toggleAppMonetizationFilter", action.payload)
          const appMonetizationID = action.payload;
          state.filters[appMonetizationID] = !state.filters[appMonetizationID];
  
          // Update isAnyFlowChecked
          state.isAnyFlowChecked = Object.values(state.filters).some(isChecked => isChecked);
      },
     uncheckAllAppMonetizationFilters: (state) => {
        state.appMonetizations.forEach(monetization => {
            state.filters[monetization.id] = false;
            });
        state.isAnyFlowChecked = false;
  
     }, 
  
    },
    extraReducers(builder){
      builder.addCase(fetchAllAppMonetizations.fulfilled, (state, action) => {
          //console.log(action)
          //console.log(action.meta)
         state.appMonetizations = action.payload.data.allMonetizations.monetizations
         state.filteredAppMonetizations = action.payload.data.allMonetizations.monetizations
         state.filters = {}
         state.isAnyFlowChecked = false
         state.isLoading = false; // Set isLoading to false once the data is fetched
      });
      builder.addCase(fetchAllAppMonetizations.pending, (state) => {
                state.isLoading = true;
      });
      builder.addCase(logout, (state, action) => {
        //console.log("logout")
        return initialState
      })
      builder.addCase(resetOtherStateAfterLogin, (state, action) => {
        //console.log("resetOtherStateAfterLogin")
        return initialState
      })
      
    }
  })
  
  // Selector to count the number of checked userflows
  export const selectCheckedCount = (state) => {
      return Object.values(state.appmonetizationsfilter.filters).filter(isChecked => isChecked).length;
  };
  

  const getAppMonetizations = state => state.appmonetizationsfilter.appMonetizations;
  const getFilters = state => state.appmonetizationsfilter.filters;
  
  //memoized selector
    export const selectFilteredAppMonetizations = createSelector(
    [getAppMonetizations, getFilters],
    (appMonetizations, filters) => appMonetizations.filter(monetization => filters[monetization.id])
    );
  
  
  // Action creators are generated for each case reducer function
  export const { toggleAppMonetizationFilter, uncheckAllAppMonetizationFilters, changeSearchTerm } = AppMonetizationsFilterSlice.actions
  
  export const appMonetizationsfilterReducer = AppMonetizationsFilterSlice.reducer;