import React, { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, EmailAuthProvider, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { auth as firebaseui } from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
const KEYS = require('../../config/keys')

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: KEYS.CLIENT_FIREBASE_API_KEY,
  authDomain: KEYS.CLIENT_FIREBASE_AUTH_DOMAIN
  // other config properties
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Get the Auth service for the default app

const FirebaseAuthUI = () => {
  useEffect(() => {

      const unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
      if (user) {
         console.log("User is logged in:", user);
        const token = await user.getIdToken();
        //console.log("token from firebase", token);
       // console.log("user from firebase", user);
        // const { creationTime, lastSignInTime } = user.metadata;
        // const creationDate = new Date(creationTime);
        // const lastSignInDate = new Date(lastSignInTime);
        // const timeDiff = lastSignInDate - creationDate;

        // Consider as new user if the difference is less than 1 minutes (60000 milliseconds)
        // if (timeDiff < 60000) {
        //   console.log("New user signed up!");
        //   //setIsNewUser(true);
        // } else {
        //   console.log("Returning user logged in!");
        //   //setIsNewUser(false);
        // }

        // Optional: send data to backend here
        //console.log({ token, isNewUser: timeDiff < 300000 });
      } else {
        //user is not logged in. Show log in button
         console.log("No user logged in.");

      }
    }, );






    // FirebaseUI config
    const uiConfig = {
     signInSuccessUrl: '/', // URL to redirect to after a successful sign-in
      signInOptions: [
        EmailAuthProvider.PROVIDER_ID,
        //GoogleAuthProvider.PROVIDER_ID,
        // Add other providers you plan to use
      ],
      callbacks: {
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            console.log("signInSuccessWithAuthResult:", authResult);
            // You can handle the redirect here if needed:
            // window.location.assign(redirectUrl);
            // return false to prevent the default redirect by FirebaseUI
            return false;
          }
        },
      // Other configuration options...
    };

    // Initialize the FirebaseUI Widget using Firebase
    const ui = firebaseui.AuthUI.getInstance() || new firebaseui.AuthUI(auth);

    // The start method will wait until the DOM is loaded
    ui.start('#firebaseui-auth-container', uiConfig);

    // Cleanup the UI and auth observer on unmount
    return () => {
      ui.delete();
      unregisterAuthObserver();
    };
  }, []);

  return <div id="firebaseui-auth-container"></div>;
};

export default FirebaseAuthUI;
