//ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


const ProtectedRoute = ({ children }) => {
    const location = useLocation();
   // Use useSelector to access isAuthenticated from the auth slice
   const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
   //console.log("isAuthenticated", isAuthenticated)
 
   if (!isAuthenticated) {

     return <Navigate to="/404" state={{ from: location }} replace/>;
   }
 
   // Render children components if the user is authenticated
   return children;
 };

export default ProtectedRoute;