// HeaderSearchSuggestionList.js
import React from 'react';
import { useDispatch } from 'react-redux';
import {selectSearchSuggestion, fetchWebAppByName} from '../../store'
import { useNavigate } from 'react-router-dom';




const HeaderSearchSuggestionList = ({suggestions, typeofSearch}) => {
 //console.log(suggestions);
  //console.log("HeaderSearchSuggestionList - typeofSearch", typeofSearch);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //console.log("HeaderSearchSuggestionList",suggestions)

   
  const suggestionClicked = (r) => {   
    const selectedSuggestion = r;
    if (selectedSuggestion.isBlurred){
        navigate('/signin');
    } else {
        dispatch(selectSearchSuggestion(selectedSuggestion.name))
        if(selectedSuggestion.href){
          navigate(selectedSuggestion.href, { replace: false});
        }
    }
     
    };

  return (
  <div className="search-result-list">
     {suggestions.map((result, i) => (
            <div className="item" onMouseDown={() => suggestionClicked(result)} key={i} style={{ display: 'flex', alignItems: 'left' }}>
              <img src={result.logo_url} alt={result.name} style={{ marginRight: '10px' }} />
              <div>
                <strong style={{ display: 'block', marginBottom: '5px' }}>{result.name}</strong>
                <div style={{ color: '#888' }}>{result.__typename}</div>
              </div>
            </div>
          ))}
    </div>
  );
};


export default HeaderSearchSuggestionList



// <div className="item" onMouseDown={()=>suggestionClicked(result.name)} key={i}>
//               <div className="header">App </div>
//             {result.name} 
//             </div>








