//enterPassComponent.js

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { userLogInWithEmailAndPassword, resetOtherStateAfterLogin } from '../../../store'
//import SimpleLoader from '../Loaders/SimpleLoader'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import SearchBarHeader from '../../SearchBarHeader/SearchBarHeader'
import { websiteName } from '../../../utils/environment'

const EnterPassword = ({ setCurrentStep, email }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasFormError, setHasFormError] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState(''); //Coudn't find your account
    const [password, setPassword] = useState(''); 
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const userEmailAddress = email


    const { isLoading, isAuthenticated, errorMessage, hasError } = useSelector((state) => state.auth)

 

    useEffect(() => {
          if (isAuthenticated) {
              // Reset the state other than auth after login
              dispatch(resetOtherStateAfterLogin());
             // Get the 'from' path from location state if it exists, otherwise default to "/"
              let redirectTo = '/';
              if (location.state && location.state.from && location.state.from.pathname) {
                redirectTo = location.state.from.pathname;
              }
              navigate(redirectTo, { replace: true });
              //navigate('/');
          }
        }, [isAuthenticated, navigate]);

    useEffect(() => {
           // console.log("hasError", hasError);
            //console.log("errorMessage", errorMessage);
            if (hasError) {
                setHasFormError(true);
                setFormErrorMessage(errorMessage);
            }
        }, [hasError]); 

    const handleEmailEditClick = () => {
        setCurrentStep(1);
    }

    const handleOnContinueClick = (e) => {
        e.preventDefault();
       // console.log(email)
        //console.log(password);
        if(password !== ""){
            //console.log("Password is required")
            dispatch(userLogInWithEmailAndPassword({email, password}))
        }
        

    }     
    const focusStyle = {
        borderRadius: '5px',
        borderWidth: '1px',
        height: '30px',
        borderColor: isFocused ? 'lighgrey' : '#888', // Change borderColor on focus to black
        boxShadow: isFocused ? '0 0 16px rgba(0, 0, 0, 0.1)' : 'none', // Optional: Add shadow on focus
        outline: 'none',
        marginBottom: hasFormError ? '-1vh' : '4vh'
    };

    return (
        <>
           
            <div className="first-container" style={{
                height: '100vh', // Make sure the parent container is full viewport height
                display: 'flex',
                justifyContent: 'center', // Center children horizontally in the container
                alignItems: 'center' // Center children vertically in the container
            }}>
                <div style={{
                    border: '1px solid #ccc',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Adjusted to add shadow on all sides
                    //padding: '20px',
                    marginTop: '-10vh', // Move up the form by adjusting the top margin using viewport height
                    marginBottom: '40px', // Increase bottom margin to maintain overall spacing
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '90%', // Use a larger percentage for smaller screens
                    maxWidth: '450px', // Ensure it doesn't get too wide on larger screens
                    borderRadius: '10px' // Added to make corners round
                }}>
                    <h4 style={{ marginBottom: '2px', paddingTop: '30px' }}>Enter your password </h4>
                    <p style={{
                        fontSize: "1em",
                        color: '#888',
                        marginTop: '2px',
                        marginBottom: '4vh',
                        paddingLeft: '5vw',
                        paddingRight: '4vw',
                        textAlign: 'center'  // Add this line to center-align content
                    }}>
                        Enter the password associated with your account<br />
                        {userEmailAddress} <span onClick={handleEmailEditClick} style={{ cursor: 'pointer', color: '#333' }}>&#9998;</span>
                    </p>
                    <form className="ui form" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left', // Center horizontally in a column flex container
                        justifyContent: 'left', // Center vertically in a column flex container
                        width: '60%' // Use the full width of the parent container
                    }}>
                        {/* Hidden username field */}
                        <input
                            type="hidden"
                            name="username"
                            value={userEmailAddress} // Assuming `userEmailAddress` holds the user's email or username
                        />
                        
                        <label htmlFor="inputField" style={{ fontSize: '13px' }}>Password</label>
                        <input
                            type="password"
                            id="inputField"
                            name="password" 
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="new-password"
                            style={{
                                ...focusStyle,
                                fontFamily: 'Lato',
                                fontSize: '14px',
                                borderColor: hasFormError ? '#ff4d4d' : '#888'
                            }}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        />
                        {hasFormError ? <p style={{
                            fontSize: '12px',
                            marginBottom: '4vh',
                            color: '#ff4d4d'
                        }}>{formErrorMessage}</p> : null}
                        <button type="submit" style={{ height: '30px', background: 'linear-gradient(to top, #000000, #555555)', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '3vh' }} onClick={handleOnContinueClick}>
                            Continue &#9656;
                        </button>
                    </form>
                    <div className="second-contained" style={{ marginTop: '10px', width: '100%', backgroundColor: '#f9f9f9', border: '1px solid #dcdcdc', padding: '20px', borderRadius: '0 0 5px 5px', textAlign: 'center' }}>
                    <p style={{ fontSize: "0.9em", color: '#888', marginTop: '1px' }}>
                            Don't have an account? <Link to="/signup" style={{ color: '#333', cursor: 'pointer', textDecoration: 'none' }}>Sign up</Link>
                        </p>
                    </div>
                </div>

            </div>


        </>
    );
}

export default EnterPassword;