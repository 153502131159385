// AppMonetizationsSidebar.js
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {  toggleAppMonetizationFilter } from '../../../store'
import { fetchAllAppMonetizations} from '../../../store/thunks/webAppsThunk'
import { useParams } from 'react-router-dom';
import SimpleLoader from '../../Loaders/SimpleLoader'
import { changeSearchTerm } from '../../../store/slices/AppMonetizationsFilterSlice';
import { addMonetization, removeMonetization } from '../../../store';


const AppMonetizationsSidebar = ({ isOpen, setIsOpen }) => {
const dispatch = useDispatch();


const appMonetizations = useSelector((state) => {
          return state.appmonetizationsfilter.filteredAppMonetizations
       }) 
//console.log("appMonetizations", appMonetizations)

const searchTerm = useSelector((state) => {
      return state.appmonetizationsfilter.searchTerm
   })



const filters = useSelector(state => state.appmonetizationsfilter.filters);
//console.log("filters", filters)


const isAnyFlowChecked = useSelector(state => state.appmonetizationsfilter.isAnyFlowChecked);
//console.log("isAnyFlowChecked", isAnyFlowChecked)



 const isLoading = useSelector((state) => {
          return state.appmonetizationsfilter.isLoading
       })  
//console.log("isLoading", isLoading)



useEffect(() => {

   if(appMonetizations.length === 0){
   dispatch(fetchAllAppMonetizations())
   }
    
  }, []);



const handleCloseSlider = (r) => {   
        const id = r.target.id;
         setIsOpen(false)
    };  

   const handleCheckboxChange = (userFlowId) => (event) => {
      const { checked } = event.target;
      //console.log("handleCheckboxChange", checked)
      dispatch(toggleAppMonetizationFilter (userFlowId));
      if(checked){
        dispatch(addMonetization(userFlowId))
      } else {
         dispatch(removeMonetization(userFlowId))
         }
    };  

const handleSearchTermChange = (event) => { 
      const newTerm = event.target.value;
      dispatch(changeSearchTerm(newTerm))
    };      
      

return (
    <div className="ui vertical right visible sidebar menu">
                      <h3 className="item">
                          App Monetizations
                      </h3>

                     <div id="search" className="ui search ">
                     <div className="ui icon input">
                       <input className=" prompt search-bar-for-flows-dropdown" 
                       type="text" 
                       style = {{color: "black"}} 
                       placeholder={"Search app monetizations.."} 
                       value={searchTerm}
                       onChange={handleSearchTermChange}
                       //onBlur={handleBlur}
                       />
                          <i className="search icon"></i>
                      
                    </div>
                    </div>
                    <div className="ui divider"></div>

                     {
                       isLoading ? 
                       <SimpleLoader/> : 
                       <>
                       { isAnyFlowChecked && 
                        <>
                              {Object.entries(filters).filter(([id, isChecked]) => isChecked).map(([id, _]) => {
                                const matchedMonetization = appMonetizations.find(monetization => monetization.id === id);
                                return matchedMonetization ? (
                                  <div className="item" key={id}>
                                    <div className="ui checkbox">
                                      <input 
                                        type="checkbox" 
                                        checked={true}
                                        onChange={handleCheckboxChange(id)}
                                      />
                                      <label>{matchedMonetization.name}</label>
                                    </div>
                                  </div>
                                ) : null;
                              })}
                              <br/>
                         </>
                         }

                         {appMonetizations.map(monetization => (
                           <div className="item" key={monetization.id}>
                             <div className="ui checkbox">
                               <input 
                                 type="checkbox" 
                                 name="example"
                                 checked={filters[monetization.id] || false}
                                 onChange={handleCheckboxChange(monetization.id)}
                               />
                               <label>{monetization.name}</label>
                             </div>
                           </div>
                         ))}
                     
                         
                       </>
                     }
                  
  </div> 
  );
};


export default AppMonetizationsSidebar






