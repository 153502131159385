// SearchBarHeader.js
import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import HeaderSearchBar from './HeaderSearchBar'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react';
import { websiteName } from '../../utils/environment'
import { logout } from '../../store'
import SimpleLoader from '../Loaders/SimpleLoader';

//import FirebaseAuthUI from '../FirebaseAuthUI/FirebaseAuthUI'
//import { getAuth, signOut } from 'firebase/auth';



const SearchBarHeader = () => {
    //const auth = getAuth();
    const [getMenuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    //console.log("is menu open", getMenuOpen)

    const { isLoading, isAuthenticated } = useSelector((state) => state.auth)


    // const handleLogout = async () => {
    //     console.log("handleLogout");
    //     try {
    //       await signOut(auth);
    //      //dispatch(clearUser());
    //       console.log('User logged out successfully');
    //     } catch (error) {
    //       console.error('Logout Error:', error);
    //     }
    //   };

    const handleLogout = () => {
        //console.log("handleLogout");
        dispatch(logout());
        navigate('/'); // navigate to the root page

    };

    const handleOnClick = (r) => {
        //const id = r.target.id;
        //console.log("ASDASD",id);
        setMenuOpen(!getMenuOpen)
    };

  

    return (
        //asdas
        <div className="pusher">

            <div className="ui borderless fixed menu">

                <Link to="/" className="item" id="extra-mini-logo-nav-bar">
                    <img className="ui image extra-mini" src="/assests/icon-02.png" />
                </Link>



                <div className="header-search-bar item">
                    <HeaderSearchBar />
                </div>




                {!isAuthenticated && (
                    <Link id="menu-bar" to="/signin" className="item">
                        Sign In
                    </Link>
                )}


                {!isAuthenticated && (
                    <Link to="/signup" className="item" id="menu-bar">
                        <button className="ui compact secondary button circular">
                            Sign Up
                        </button>
                    </Link>
                )}




                <div className="toc-sidebar-icon item">
                    <a className="toc item"
                        onClick={handleOnClick}
                    >
                        <i className="sidebar icon"></i>
                    </a>
                </div>
                <div
                    className={getMenuOpen ? "ui vertical right visible sidebar menu" : "ui vertical right sidebar menu"}
                >
                    <div>
                        <a className="item">
                            <i className="close icon"
                                onClick={(e) => {
                                    e.stopPropagation(); // This stops the click event from propagating to the button
                                    handleOnClick(e);
                                }}

                            ></i>
                        </a>
                    </div>

                    <div className="ui divider"></div>


                    {!isAuthenticated && (
                        <>
                            <button 
                            // id="sidebar" 
                            className="ui secondary button circular" onClick={() => navigate('/signup')}>
                                Sign Up
                            </button>
                            <br />
                            <button 
                            // id="sidebar" 
                            className="ui button circular" onClick={() => navigate('/signin')}>
                                Sign In
                            </button>
                            <div 
                            // id="sidebar" 
                            className="ui divider"></div>
                        </>
                    )}





                    <div id="sidebar-meeako-nav-item">
                        <Link to="/" className={location.pathname === "/" ? "active item" : "item"}
                            onClick={handleOnClick}
                        >
                            <div id="sidebar-meeako-nav-item">Meeako
                                <img className="ui image extra-extra-mini right floated" src="/assests/icon-02.png" />
                            </div>
                        </Link>
                        <div className="ui divider"></div>
                    </div>



                    {isAuthenticated && (
                        <>
                            <div className='item'>
                                <div className="ui header">Account Management</div>
                            </div>  
                            <Link to="/profile/password" className={location.pathname.startsWith("/profile") ? "active item" : "item"}
                                    onClick={handleOnClick}
                                >
                                    <i className="user icon"></i>
                                    <div className="">Profile Settings</div>
                            </Link>
                            
                            <a className="item" >
                                <i className="sign-out icon"></i>
                                <div className="" onClick={handleLogout}>Logout</div>
                            </a>
                            <div className="ui divider"></div>
                        </>
                    )}
                    
                    <div className='item'>
                        <div className="ui header">Contact</div>
                    </div>
                    
                    <a className="item" href="mailto:info@meeako.com?subject=Website Inquiry or Suggestion">
                    <i className="mail icon"></i>
                        Questions or Suggestions?
                    </a>
                    <div className="ui divider"></div>



                    <div className='item'>
                        <div className="ui header">Legal</div>
                     </div>   

                    <Link to="/policies/privacy-policy" className={location.pathname === "/policies/privacy-policy" ? "active item" : "item"}
                        onClick={handleOnClick}>
                        <i className="lock icon"></i>
                        <div className="">Privacy Policy</div>  
                    </Link>
                    <Link to="/policies/terms-of-service" className={location.pathname === "/policies/terms-of-service" ? "active item" : "item"}
                        onClick={handleOnClick}>
                        <i className="file alternate icon"></i>
                        <div className="">Terms of Service</div>
                    </Link>
                    <div className="ui divider"></div>
                </div>


            </div>

        </div>
    );
};


export default SearchBarHeader


